<template>
  <div class="p-md-5 p-3">
    <div class="mb-3">
      <label class="form-label fs-6">CURRENT PASSWORD</label>
      <div
        :class="[
          v$.password.$error && 'border-danger',
          'input-group mx-auto form-control d-flex justify-content-between',
        ]"
      >
        <input
          v-model="passwordData.password"
          :type="showPassword ? 'text' : 'password'"
          class="input border-0 no-outline"
        />
        <div class="input-group-append">
          <font-awesome-icon
            v-if="!showPassword"
            @click="showPassword = true"
            class="passwordVisibilityButton"
            icon="fa-regular fa-eye-slash"
          />
          <font-awesome-icon
            v-else
            @click="showPassword = false"
            id="passwordVisibilityButton"
            icon="fa-regular fa-eye"
          />
        </div>
      </div>

      <span class="mt-3 text-danger fs-6" v-if="v$.password.$error">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
        {{ v$.password.$errors[0].$message }}
      </span>
    </div>
    <div class="mb-3">
      <label class="form-label fs-6">NEW PASSWORD</label>
      <div
        :class="[
          v$.password.$error && 'border-danger',
          'input-group mx-auto form-control d-flex justify-content-between',
        ]"
      >
        <input
          v-model="passwordData.newPassword"
          :type="showNewPassword ? 'text' : 'password'"
          class="input border-0 no-outline"
        />
        <div class="input-group-append">
          <font-awesome-icon
            v-if="!showNewPassword"
            @click="showNewPassword = true"
            class="passwordVisibilityButton"
            icon="fa-regular fa-eye-slash"
          />
          <font-awesome-icon
            v-else
            @click="showNewPassword = false"
            id="passwordVisibilityButton"
            icon="fa-regular fa-eye"
          />
        </div>
      </div>
      <PasswordStrength
        v-if="passwordData.newPassword !== ''"
        :password="passwordData.newPassword"
      />

      <span class="mt-3 text-danger fs-6" v-if="v$.newPassword.$error">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
        {{ v$.newPassword.$errors[0].$message }}
      </span>
    </div>
    <div class="mb-3">
      <label class="form-label fs-6">CONFIRM PASSWORD</label>
      <div
        :class="[
          v$.password.$error && 'border-danger',
          'input-group mx-auto form-control d-flex justify-content-between',
        ]"
      >
        <input
          v-model="passwordData.confirmPassword"
          :type="showConfirmPassword ? 'text' : 'password'"
          class="input border-0 no-outline"
        />
        <div class="input-group-append">
          <font-awesome-icon
            v-if="!showConfirmPassword"
            @click="showConfirmPassword = true"
            class="passwordVisibilityButton"
            icon="fa-regular fa-eye-slash"
          />
          <font-awesome-icon
            v-else
            @click="showConfirmPassword = false"
            id="passwordVisibilityButton"
            icon="fa-regular fa-eye"
          />
        </div>
      </div>
      <span class="mt-3 text-danger fs-6" v-if="v$.confirmPassword.$error">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
        Passwords do not match
      </span>
    </div>
    <a
      @click="navigateToForgotPassword"
      class="text-decoration-none text-dark d-block mb-4"
      >Forgot password?
    </a>
    <button
      :disabled="loading"
      type="button"
      @click="changePassword"
      class="zoom btn bg-success text-white w-100 mb-4"
    >
      <div v-if="loading" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span v-else>CHANGE PASSWORD</span>
    </button>
  </div>
</template>

<script setup>
//components
import PasswordStrength from "./PasswordStrength.vue";

//imports
import apiClient from "@/apiClient";
import { storeToRefs } from "pinia";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import {required, minLength, sameAs, helpers} from "@vuelidate/validators";

//stores
import useNotificationStore from "@/stores/notificationStore";
import useAuthStore from "@/stores/authStore";
import useNavigationStore from "@/stores/navigationStore";

const { authFormState, nextRoute } = storeToRefs(useNavigationStore());
const { showNotification } = useNotificationStore();

//router
const router = useRouter();

//passwordData
const passwordData = ref({
  password: "",
  newPassword: "",
  confirmPassword: "",
});

const loading = ref(false);
const showPassword = ref(false);
const showNewPassword = ref(false);
const showConfirmPassword = ref(false);

//validation rules
const validationRules = computed(() => ({
  password: { required: helpers.withMessage('Password is required', required) },
  newPassword: {
    required: helpers.withMessage('Password is required', required),
    minLength: helpers.withMessage('Password should be at least 8 character long', minLength(8))
  },
  confirmPassword: {
    required: helpers.withMessage('Password is required', required),
    sameAs: helpers.withMessage('Passwords do not match', sameAs(passwordData.value.newPassword)) },
}));

const v$ = useVuelidate(validationRules, passwordData.value);

//methods
const navigateToForgotPassword = () => {
  authFormState.value = "forgotPassword";
};

const changePassword = async () => {
  //validate form
  //if form is valid
  //call the auth store login method
  try {
    v$.value.$validate();

    if (!v$.value.$error) {
      const body = {
        password: passwordData.value.password,
        newPassword: passwordData.value.newPassword,
      };

      loading.value = true;

      const { data, status } = await apiClient(
        "/password",
        "PATCH",
        JSON.stringify(body)
      );

      if (status !== 200) throw new Error(data.info);

      const notificationObject = {
        isSuccess: true,
        header: "Password changed",
        text: "Password changed successfully",
      };
      showNotification(notificationObject);
      loading.value = false;

      //close model
      const modal = document.getElementById("formModal");
      const formModal = bootstrap.Modal.getInstance(modal);
      formModal.hide();

      authFormState.value = "login";
    }
  } catch (error) {
    error.name = "";
    const notificationObject = {
      isSuccess: false,
      header: "Failed to change password",
      text: error.toString(),
    };
    showNotification(notificationObject);
    loading.value = false;
  }
};
</script>

<style scoped>
.btn,
.form-control {
  border-radius: 50px !important;
}

.passwordVisibilityButton {
  cursor: pointer;
}
</style>
