<template>
  <nav
    class="navbar navbar-expand-md navbar-light bg-white d-none d-md-block border-bottom border-2"
    id="nav_top"
  >
    <div id="mainNav" class="container text-dark g-0">
      <div class="d-flex w-100 justify-content-start py-2">

<!--        <router-link-->
<!--            @mouseenter="setHovered('products')"-->
<!--            @mouseleave="setHovered('')"-->
<!--            :class="[-->
<!--              ((route.path.includes('/products') && hovered === '') ||-->
<!--                hovered === 'products') &&-->
<!--                'active',-->
<!--              'text-dark text-decoration-none me-3',-->
<!--            ]"-->
<!--            to="/products"-->
<!--            v-if="user?.accountType === 'admin'"-->
<!--        >-->
<!--          PRODUCTS-->
<!--        </router-link>-->
        <router-link
            v-if="!(user?.accountType === 'admin')"
            @mouseenter="setHovered('products')"
            @mouseleave="setHovered('')"
            :class="[
              ((route.path.includes('/products') && hovered === '') ||
                hovered === 'products') &&
                'active',
              'text-dark text-decoration-none me-3',
            ]"
            to="/products/all"
        >
          PRODUCTS
        </router-link>

        <CategoryDropdown
          @mouseenter="setHovered('categories')"
          @mouseleave="setHovered('')"
          :class="[hovered === 'categories' && 'active']"
          v-if="user?.accountType === 'client' || !isAuthenticated"
        />
<!--        <router-link-->
<!--          v-if="user?.accountType === 'admin'"-->
<!--          @mouseenter="setHovered('categories')"-->
<!--          @mouseleave="setHovered('')"-->
<!--          :class="[-->
<!--            ((route.path == '/categories' && hovered === '') ||-->
<!--              hovered === 'categories') &&-->
<!--              'active',-->
<!--            'text-dark text-decoration-none mx-3',-->
<!--          ]"-->
<!--          to="/categories"-->
<!--        >-->
<!--          CATEGORIES-->
<!--        </router-link>-->

<!--        <router-link-->
<!--          @mouseenter="setHovered('subcategories')"-->
<!--          @mouseleave="setHovered('')"-->
<!--          :class="[-->
<!--            ((route.path == '/subcategories' && hovered === '') ||-->
<!--              hovered === 'subcategories') &&-->
<!--              'active',-->
<!--            'text-dark text-decoration-none mx-3',-->
<!--          ]"-->
<!--          to="/subcategories"-->
<!--          v-if="user?.accountType === 'admin'"-->
<!--        >-->
<!--          SUBCATEGORIES-->
<!--        </router-link>-->


        <router-link
          @mouseenter="setHovered('orders')"
          @mouseleave="setHovered('')"
          :class="[
            ((route.path == '/orders' && hovered === '') ||
              hovered === 'orders') &&
              'active',
            'text-dark text-decoration-none mx-3',
          ]"
          to="/orders"
          v-if="isAuthenticated"
        >
          ORDERS
        </router-link>


<!--        <router-link-->
<!--          @mouseenter="setHovered('banners')"-->
<!--          @mouseleave="setHovered('')"-->
<!--          :class="[-->
<!--            ((route.path == '/banners' && hovered === '') ||-->
<!--              hovered === 'banners') &&-->
<!--              'active',-->
<!--            'text-dark text-decoration-none mx-3',-->
<!--          ]"-->
<!--          to="/banners"-->
<!--          v-if="user?.accountType === 'admin'"-->
<!--        >-->
<!--          BANNERS-->
<!--        </router-link>-->

<!--        <router-link-->
<!--            @mouseenter="setHovered('campaigns')"-->
<!--            @mouseleave="setHovered('')"-->
<!--            :class="[-->
<!--            ((route.path == '/campaigns' && hovered === '') ||-->
<!--              hovered === 'campaigns') &&-->
<!--              'active',-->
<!--            'text-dark text-decoration-none mx-3',-->
<!--          ]"-->
<!--            to="/campaigns"-->
<!--            v-if="user?.accountType === 'admin'"-->
<!--        >-->
<!--          CAMPAIGNS-->
<!--        </router-link>-->


        <div class="dropdown"
             @mouseenter="setHovered('support')"
             @mouseleave="setHovered('')"
             :class="[hovered === 'support' && 'active']">

          <button
              class="border-0 btn text-dark dropdown-toggle me-2 pt-0"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
          >
            SUPPORT
          </button>


          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">

            <router-link
                class="dropdown-item"
                @mouseenter="setHovered('contact-us')"
                @mouseleave="setHovered('')"
                :class="[
            ((route.path == '/contact-us' && hovered === '') ||
              hovered === 'contact-us') &&
              'active-firefox',
            'text-dark text-decoration-none',
          ]"
                to="/contact-us"
            >
              CONTACT US
            </router-link>

            <router-link
                class="dropdown-item"
                @mouseenter="setHovered('blogs')"
                @mouseleave="setHovered('')"
                :class="[
            ((route.path == '/blogs' && hovered === '') ||
              hovered === 'blogs') &&
              'active-firefox',
            'text-dark text-decoration-none',
          ]"
                to="/blogs"
            >
              BLOG
            </router-link>
          </div>

        </div>



        <div class="dropdown"
             @mouseenter="setHovered('services')"
             @mouseleave="setHovered('')"
             :class="[hovered === 'services' && 'active']">

          <button
              class="border-0 btn text-dark dropdown-toggle me-2 pt-0"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
          >
            SERVICES
          </button>


          <div class="dropdown-menu flex" aria-labelledby="dropdownMenuLink">

            <div style="width: 300px;" v-for="service in services" v-if="services.length !== 0" :key="service.id">
                  <router-link
                      @mouseenter="setHovered(`${service.heading}`)"
                      @mouseleave="setHovered('')"
                      :class="[
                      ((route.path === `/service/${service._id}` && hovered === '') ||
                       hovered === `${service.heading}`) &&
                        'active',
                        'text-dark text-decoration-none mx-3',
                      ]"
                      :to="`/service/${service._id}`"
                    >
                    {{ service.heading }}
                  </router-link>
            </div>


          </div>
        </div>



<!--        <router-link-->
<!--          @mouseenter="setHovered('shipping_methods')"-->
<!--          @mouseleave="setHovered('')"-->
<!--          :class="[-->
<!--            ((route.path == '/shipping_methods' && hovered === '') ||-->
<!--              hovered === 'shipping_methods') &&-->
<!--              'active',-->
<!--            'text-dark text-decoration-none ms-3',-->
<!--          ]"-->
<!--          to="/shipping_methods"-->
<!--          v-if="user?.accountType === 'admin'"-->
<!--        >-->
<!--          SHIPPING-->
<!--        </router-link>-->

<!--        <router-link-->
<!--            @mouseenter="setHovered('settings')"-->
<!--            @mouseleave="setHovered('')"-->
<!--            :class="[-->
<!--            ((route.path == '/settings' && hovered === '') ||-->
<!--              hovered === 'settings') &&-->
<!--              'active',-->
<!--            'text-dark text-decoration-none ms-3',-->
<!--          ]"-->
<!--            to="/settings"-->
<!--            v-if="user?.accountType === 'admin'"-->
<!--        >-->
<!--          SETTINGS-->
<!--        </router-link>-->

      </div>
    </div>
  </nav>
</template>

<script setup>
//imports
import { ref } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";

//components
import CategoryDropdown from "./CategoryDropdown.vue";

//store
import useAuthStore from "@/stores/authStore";

const { isAuthenticated, user } = storeToRefs(useAuthStore());

const route = useRoute();

//data
const hovered = ref("");

//methods
const setHovered = (hoveredTab) => {
  hovered.value = hoveredTab;
};

import useServiceStore from "@/stores/serviceStore";
import {onMounted} from "vue";


const {getAllServices} = useServiceStore();
const {services} = storeToRefs(useServiceStore());

const fetchingData = ref(true)


onMounted(async () => {
  await getAllServices();

  fetchingData.value = false;
})


</script>



<style scoped>
.dropdown-item {
  background-color: transparent !important;
}

.active-firefox{
  color: var(--success) !important;
}
.active {
  border-bottom: 2px solid var(--success);
  color: var(--success) !important;
}

/* #mainNav {
  overflow-x: scroll;
  overflow-y: hidden;
} */


#mainNav::-webkit-scrollbar {
  width: 0.1em; /* Adjust the width to make it thinner */
}

#mainNav::-webkit-scrollbar-thumb {
  background: var(--success);
  border-radius: 100vw;
  /* Adjust the height of the scrollbar thumb */
  height: 0.1em; /* You can adjust this value to make it thinner or thicker */
}

#mainNav::-webkit-scrollbar-track {
  background: var(--light-grey);
  border-radius: 100vw;
  margin-block: 0.3em;
}
</style>
