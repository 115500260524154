<template>
  <form @submit.prevent="registerUser" class="p-md-5 p-3" @focusout="v$.$reset()">
    <div class="mb-3">
      <label :class="[v$.username.$error && 'text-danger', 'form-label fs-6']"
        >USERNAME</label
      >
      <input
        type="text"
        v-model="data.username"
        placeholder="Username"
        :class="[
          v$.username.$error && 'border-danger',
          'form-control no-outline',
        ]"
      />
      <span class="mt-3 text-danger fs-6" v-if="v$.username.$error">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
        {{ v$.username.$errors[0].$message }}
      </span>
    </div>
    <div class="mb-3">
      <label :class="[v$.email.$error && 'text-danger', 'form-label fs-6']"
        >EMAIL</label
      >
      <input
        v-model="data.email"
        placeholder="Email"
        type="email"
        :class="[v$.email.$error && 'border-danger', 'form-control no-outline']"
      />
      <span class="mt-3 text-danger fs-6" v-if="v$.email.$error">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
        {{ v$.email.$errors[0].$message }}
      </span>
    </div>
    <div class="mb-3">
      <label
        for="passwordInput"
        :class="[v$.password.$error && 'text-danger', 'form-label fs-6']"
        >PASSWORD</label
      >
      <div
        :class="[
          v$.password.$error && 'border-danger',
          'input-group mx-auto form-control d-flex justify-content-between',
        ]"
      >
        <input
          v-model="data.password"
          placeholder="Password"
          :type="showPassword ? 'text' : 'password'"
          class="input border-0 no-outline"
        />
        <div class="input-group-append">
          <font-awesome-icon
            v-if="!showPassword"
            @click="showPassword = true"
            id="passwordVisibilityButton"
            icon="fa-regular fa-eye-slash"
          />
          <font-awesome-icon
            v-else
            @click="showPassword = false"
            class="passwordVisibilityButton"
            icon="fa-regular fa-eye"
          />
        </div>
      </div>
      <PasswordStrength v-if="data.password !== ''" :password="data.password"/>

    </div>
    <div class="mb-3"><span class="mt-3 text-danger fs-6" v-if="v$.password.$error">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
        {{ v$.password.$errors[0].$message }}
      </span>
      <label
        for="passwordInput"
        :class="[v$.confirmPassword.$error && 'text-danger', 'form-label fs-6']"
        >CONFIRM PASSWORD</label
      >
      <div
        :class="[
          v$.password.$error && 'border-danger',
          'input-group mx-auto form-control d-flex justify-content-between',
        ]"
      >
        <input
          v-model="data.confirmPassword"
          placeholder="Confirm Password"
          :type="showConfirmPassword ? 'text' : 'password'"
          class="input border-0 no-outline"
        />
        <div class="input-group-append">
          <font-awesome-icon
            v-if="!showConfirmPassword"
            @click="showConfirmPassword = true"
            class="passwordVisibilityButton"
            icon="fa-regular fa-eye-slash"
          />
          <font-awesome-icon
            v-else
            @click="showConfirmPassword = false"
            id="passwordVisibilityButton"
            icon="fa-regular fa-eye"
          />
        </div>
      </div>
      <span class="mt-3 text-danger fs-6" v-if="v$.confirmPassword.$error">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
        Passwords do not match
      </span>
    </div>
    <button
      :disabled="data.loading"
      type="submit"
      class="zoom btn bg-success text-white w-100 mb-4"
    >
      <div v-if="data.loading" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span v-else>REGISTER</span>
    </button>

    <a @click="navigateToLogin" class="text-decoration-none d-block text-center"
      ><span class="text-dark">Already have an account? </span>Sign in here</a
    >

    <LoginWithFacebook class="mt-4"/>
    <LoginWithGoogle class="mt-4"/>
  </form>
</template>

<script setup>
//components
import PasswordStrength from "./PasswordStrength.vue"
import LoginWithGoogle from "@/components/client/auth/LoginWithGoogle";
import LoginWithFacebook from "@/components/client/auth/LoginWithFacebook";


//imports
import { storeToRefs } from "pinia";
import { ref, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import {required, email, minLength, sameAs, helpers} from "@vuelidate/validators";

//store
import useAuthStore from "@/stores/authStore";
import useNavigationStore from "@/stores/navigationStore";
import useNotificationStore from "@/stores/notificationStore";

const { authFormState } = storeToRefs(useNavigationStore());
const { showNotification } = useNotificationStore();
const { register } = useAuthStore();

//data
const data = ref({
  email: "",
  username: "",
  password: "",
  confirmPassword: "",
  loading: false,
});

const showConfirmPassword = ref(false);
const showPassword = ref(false);

//validation rules
const validationRules = computed(() => ({
  username: { required: helpers.withMessage('Username is required', required) },
  email: {
    required: helpers.withMessage('Email is required', required),
    email: helpers.withMessage('Enter a valid Email', email),
  },
  password:{
    required: helpers.withMessage('Password is required', required),
    minLength: helpers.withMessage('Password should be at least 8 character long', minLength(8))
  },
  confirmPassword: { required, sameAs: sameAs(data.value.password) },
}));

const v$ = useVuelidate(validationRules, data.value);

//methods
const navigateToLogin = () => {
  authFormState.value = "login";
};

const registerUser = async () => {
  const registerDetails = {
    username: data.value.username,
    email: data.value.email,
    password: data.value.password,
  };

  try {
    //validate form using vuelidate

    v$.value.$validate();

    //if no error then send register request
    if (!v$.value.$error) {
      //call the auth store login method
      data.value.loading = true;
      const registerSuccess = await register(registerDetails);
      const notificationObject = {
        isSuccess: registerSuccess,
        header: "Success",
        text: "Signed up successfully",
      };

      //show notification
      showNotification(notificationObject);

      //stop loading
      data.value.loading = false;

      //navigate to email verification
      authFormState.value = "emailVerification";

      //close model
      // const modal = document.getElementById("formModal");
      // const formModal = bootstrap.Modal.getInstance(modal);
      // formModal.hide();

      data.value.password = "";
      data.value.confirmPassword = "";
      data.value.email = "";
      data.value.username = "";

      return;
    }
  } catch (error) {
    error.name = "";
    const notificationObject = {
      isSuccess: false,
      header: "Failed to sign up",
      text: error.toString(),
    };
    showNotification(notificationObject);
    data.value.loading = false;
  }
};
</script>

<style scoped>
.btn,
.form-control {
  border-radius: 50px !important;
}

.passwordVisibilityButton {
  cursor: pointer;
}
</style>
