import { createRouter, createWebHistory } from "vue-router";
import useAuthStore from "@/stores/authStore";
import useNavigationStore from "@/stores/navigationStore";
import HomeView from "../views/Client/HomeView.vue";
import DashboardLayout from "@/views/layouts/DashboardLayout.vue";
import DefaultLayout from "@/views/layouts/DefaultLayout.vue";

const routes = [
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
      },
      {
        path: "/blog/:id",
        name: "single_blog",
        component: () => import("../views/Client/blog/ViewBlogView.vue"),
      },
      {
        path: "/checkout",
        name: "checkout",
        component: () => import("@/views/Client/products/CheckoutView.vue"),
      },
      {
        path: "/friend_payment/:id",
        name: "friend_payment",
        component: () => import("../views/Client/products/FriendPaymentView.vue"),
      },
      {
        path: "/invoice/:id",
        name: "shared_invoice",
        component: () => import("../views/Shared/Orders/InvoiceView.vue"),
      },
      {
        path: "/service/:id",
        name: "single_service",
        component: () => import("../views/Client/services/ViewServiceView.vue"),
      },
   
      {
        path: "/search",
        name: "search_product",
        component: () => import("../views/Shared/SearchProductView.vue"),
      },
      {
        path: "/OAuthRedirecting",
        name: "oauth_redirecting",
        component: () => import("../views/Client/auth/OAuthRedirect.vue"),
      },
      {
        path: "/orders",
        name: "orders_all",
        meta: { requiresAuth: true },
        component: () => import("../views/Client/orders/OrdersView.vue"),
      },
      {
        path: "/orders/:id",
        name: "single_order",
        component: () => import("../views/Shared/Orders/SingleOrderView.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        meta: { requiresAuth: true },
        component: () => import("../views/Shared/ProfileView.vue"),
      },
      {
        path: "/products/:id",
        name: "productsByCategory",
        component: () => import("../views/Client/products/ProductsView.vue"),
      },
      {
        path: "/404",
        name: "404",
        component: () => import("../views/Shared/Errors/404.vue"),
      },
      {
        path: "/product/:id",
        name: "productView",
        component: () => import("@/views/Client/products/ProductView.vue"),
      },
      {
        path: "/resetPassword/:id",
        name: "reset password",
        component: () => import("@/views/Client/auth/ForgotPasswordView.vue"),
      },
      {
        path: "/emailVerification/:id",
        name: "emailVerification",
        component: () =>
          import("@/views/Client/auth/EmailVerificationView.vue"),
      },
      {
        path: "/about-us",
        name: "aboutUs",
        component: () => import("@/views/Shared/Footer/AboutUs.vue"),
      },
      {
        path: "/contact-us",
        name: "contactUs",
        component: () => import("@/views/Shared/Footer/ContactUs.vue"),
      },
      {
        path: "/faqs",
        name: "faqs",
        component: () => import("@/views/Shared/Footer/FAQS.vue"),
      },
      {
        path: "/privacy-policy",
        name: "privacyPolicy",
        component: () => import("@/views/Shared/Footer/PrivacyPolicy.vue"),
      },
      {
        path: "/terms-and-conditions",
        name: "termsAndCondition",
        component: () => import("@/views/Shared/Footer/TermsAndConditions.vue"),
      },
      {
        path: "/blogs",
        name: "blogsView",
        component: () => import("../views/Client/blog/BlogsView.vue"),
      },
    ],
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    children: [
      {
        path: "currency",
        name: "currency",
        component: () => import("@/views/Admin/dashboard/currencyView.vue"),
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Admin/dashboard/dashboardView.vue"),
      },
      {
        path: "settings",
        name: "settings",
        component: () => import("@/views/Admin/settings/SettingsView.vue"),
      },
      {
        path: "invoices",
        name: "invoices",
        component: () => import("@/views/Admin/orders/InvoicesView.vue"),
      },
      {
        path: "invoice/:id",
        name: "invoice",
        component: () => import("@/views/Admin/orders/SingleInvoiceView.vue"),
      },
      {
        path: "partners",
        name: "partners",
        component: () => import("@/views/Admin/partners/PartnersView.vue"),
      },
      {
        path: "services",
        name: "services",
        component: () => import("@/views/Admin/services/ServicesView.vue"),
      },
      {
        path: "create_partner",
        name: "create_partner",
        component: () =>
          import("@/views/Admin/partners/CreatePartnerVIew.vue"),
      },
      {
        path: "create_service",
        name: "create_service",
        component: () =>
          import("@/views/Admin/services/CreateServicesView.vue"),
      },
      {
        path: "create_invoice",
        name: "create_invoice",
        component: () =>
          import("@/views/Admin/orders/CreateInvoiceView.vue"),
      },
      {
        path: "partner/:id/edit",
        name: "edit_partner",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import("../views/Admin/partners/EditPartnerView.vue"),
      },
      {
        path: "service/:id/edit",
        name: "edit_service",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import("../views/Admin/services/EditServiceView.vue"),
      },
      {
        path: "campaigns/email/create",
        name: "create_email_campaign",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () =>
          import("@/views/Admin/campaigns/CreateEmailCampaignView.vue"),
      },
      {
        path: "campaigns/contacts",
        name: "contacts",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import("@/views/Admin/campaigns/ContactsView.vue"),
      },
      {
        path: "campaigns",
        name: "campaigns",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import("@/views/Admin/campaigns/CampaignsView.vue"),
      },
      {
        path: "campaigns/sms/create",
        name: "create_sms_campaign",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () =>
          import("@/views/Admin/campaigns/CreateSMSCampaignView.vue"),
      },
      {
        path: "shipping_methods",
        name: "shippingMethods",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () =>
          import("../views/Admin/shipping/ShippingMethodsView.vue"),
      },
      {
        path: "subcategories",
        name: "subcategories",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () =>
          import("@/views/Admin/categories/SubcategoriesView.vue"),
      },
      {
        path: "create_banner",
        name: "create_banner",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import("../views/Admin/banners/CreateBannerView.vue"),
      },
      {
        path: "banner/:id/edit",
        name: "edit_banner",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import("../views/Admin/banners/EditBannerView.vue"),
      },
      {
        path: "create_article",
        name: "create_article",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import("../views/Admin/blog/CreateArticleView.vue"),
      },
      {
        path: "article/:id/edit",
        name: "edit_article",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import("../views/Admin/blog/EditArticleView.vue"),
      },
      {
        path: "create_product",
        name: "create_product",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () =>
          import("../views/Admin/products/CreateProductView.vue"),
      },
      {
        path: "product/:id/edit",
        name: "edit_product",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import("../views/Admin/products/EditProductView.vue"),
      },
      {
        path: "products",
        name: "products",
        meta: { requiresAuth: true, requiresAdmin: true },
        component: () => import("../views/Admin/products/ProductsView.vue"),
      },
      {
        path: "banners",
        name: "banner",
        component: () => import("../views/Admin/banners/BannerView.vue"),
      },
      {
        path: "articles",
        name: "articles",
        component: () => import("../views/Admin/blog/BlogsView.vue"),
      },
      {
        path: "categories",
        name: "categories",
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
        component: () => import("../views/Admin/categories/CategoriesView.vue"),
      },
      {
        path: "orders",
        name: "orders",
        meta: { requiresAuth: true },
        component: () => import("../views/Admin/orders/OrdersView.vue"),
      },
      {
        path: "searches",
        name: "searches",
        meta: { requiresAuth: true, requiresAdmin: true },
        component: () => import("../views/Admin/searches/SearchesView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

/**
 * This function acts as a navigation guard before any
 * navigation
 * @param to (the route were going to)
 * @param from(the route were going to)
 */

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const navigationStore = useNavigationStore();

  //store the from route in local storage
  for (const route of to.matched) {
    /*
     *Check if page exists else show a 404 error page
     */
    /***
     * If route doesnot require auth then proceed
     */
    if (!route.meta.requiresAuth) {
      return next();
    }
    /**
     * if it needs auth, check the state of the user if is logged in
     * if not redirect to login page
     */
    if (!authStore.isAuthenticated) {
      next(from.path);
      return navigationStore.openAuthFormModal("login", to.path);
    }

    /* If the user is logged in check if he has permission to access resources
     * if not remain to the current page
     */
    if (!route.requiresAdmin) {
      return next();
    }

    if (authStore.accountType === "admin") {
      return next();
    }

    //remain to the current path
    return next(from.path);
  }
});

export default router;
