<template>
  <div class="dropdown">
    <button
      id="categoryButton"
      class="border-0 btn text-dark dropdown-toggle me-2 pt-0"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      SHOP
    </button>
    <div
      id="dropdownMenu"
      class="dropdown-menu me-5 shadow"
      @scroll="handleDropDownScroll($event)"
    >
      <div class="row p-3">
        <div class="col-7 border-end">
          <ul class="list-unstyled">
            <li
              class="pb-3 mb-2"
              @mouseenter="getSubcategories($event, category._id)"
              :key="category._id"
              v-for="category in allCategories"
            >
              <div class="d-flex justify-content-between">
                {{ category.name }}
                <font-awesome-icon icon="fa-solid fa-chevron-right" />
              </div>
            </li>
          </ul>
        </div>
        <div class="col-5">
          <ul id="subcategories" class="list-unstyled">
            <li
              class="pb-3 mb-1"
              :key="subcategory._id"
              v-for="subcategory in displayedSubcategories"
            >
              <div>
                <router-link
                  :to="`/products/${subcategory._id}`"
                  class="text-decoration-none text-dark"
                >
                  {{ subcategory.name }}
                  <span
                    style="font-size: 12px"
                    class="ms-1 rounded text-white bg-success px-1"
                  >
                    {{ subcategory.numberOfProducts }}
                  </span>
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";

//stores
import useCategoryStore from "@/stores/categoryStore";
import useSubcategoryStore from "@/stores/subcategoryStore";

const { categories } = storeToRefs(useCategoryStore());
const { getSubcategoriesByCategory } = storeToRefs(useSubcategoryStore());
const { getAllCategories } = useCategoryStore();
const { getAllSubcategories } = useSubcategoryStore();

//data
const allCategories = ref([]);
const displayedSubcategories = ref([]);

//hooks
onMounted(async () => {
  await getAllCategories("numberOfSubcategories");
  allCategories.value = categories.value.filter(
    (category) => category.numberOfSubcategories >= 1
  );

  await getAllSubcategories();
  displayedSubcategories.value = getSubcategoriesByCategory.value(
    allCategories.value[0]?._id
  );
});

//methods
const getSubcategories = (event, categoryId) => {
  // Update the displayed subcategories
  displayedSubcategories.value = getSubcategoriesByCategory.value(categoryId);
};

const handleDropDownScroll = (e) => {
  // Get the dropdown menu
  // const dropdownMenu = event.target.nextElementSibling;
  const dropdownSubcategories = document.getElementById("subcategories");
  const dropdownMenu = document.getElementById("dropdownMenu");
  const scrollPosition= e.target.scrollTop

  // // Calculate the position to display the subcategories
  const top = scrollPosition;
  const padding = 16;

  dropdownSubcategories.style.position = "absolute";
  dropdownSubcategories.style.top = `${top + padding}px`;

};
</script>

<style scoped>
#dropdownMenu {
  width: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 80vh;
}

#dropdownMenu::-webkit-scrollbar {
  width: 0.6em;
}

#dropdownMenu::-webkit-scrollbar-thumb {
  background: var(--success);
  border-radius: 100vw;
}
#dropdownMenu::-webkit-scrollbar-track {
  background: var(--light-grey);
  border-radius: 100vw;
  margin-block: 0.3em;
}

#dropdownMenu li:hover {
  cursor: pointer;
}

#categoryButton:focus {
  border: none;
}

#categoryButton {
  border: none;
}
</style>
