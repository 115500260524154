import { defineStore } from "pinia";
import apiClient from "@/apiClient";

const useCurrencyStore = defineStore("currency", {
  state: () => ({
    currencyData: null,
  }),

  actions: {
    async updateCurrency(currency) {
      const { data, status } = await apiClient(
        "/currency",
        "post",
        JSON.stringify(currency)
      );

      if (status !== 201) throw new Error(data.info);

      console.log(data.currency);

      //reset password token
      this.currencyData = data.currency;

      return true;
    },
    async getCurrency() {
      if (this.currencyData) return;

      const { data, status } = await apiClient(`/currency`, "get");

      if (status !== 200) throw new Error(data.info);

      this.currencyData = data;
    },
  },
});

export default useCurrencyStore;
