import { defineStore } from "pinia";
import apiClient from "@/apiClient";
export default defineStore("service", {
    state: () => ({
        services: [],
        latestServices: [],
        fetchedService: "",
        fetchedSingleService: {},
        imagesOfFetchedSingleService : []
    }),

    actions: {
        async createService(service) {
            const { data, status } = await apiClient(
                "/services",
                "post",
                JSON.stringify(service)
            );

            if (status !== 201) throw new Error(data.info);

            //add the return service to the (beginning) of the services array
            this.services.unshift(data.Service);

            //console.log(this.services)

            return true;
        },

        async getSingleService(service_id) {
            const { data, status } = await apiClient(`/service/${service_id}`, "get");

            if (status !== 200) throw new Error(data.info);

            this.fetchedSingleService = data;
        },
        async deleteService(id) {
            const { data, status } = await apiClient(`/service/${id}`, "DELETE");

            if (status !== 201) throw new Error(data.info);

            this.services = this.services.filter(
                (service) => id !== service._id
            );
        },
        async getAllServices() {

            const { data, status } = await apiClient("/services", "get");

            if (status !== 200) throw new Error(data.info);

            this.services = data;
        },
        async getLatestServices() {

            const { data, status } = await apiClient("/latest_services", "get");

            if (status !== 200) throw new Error(data.info);

            this.latestServices = data;
        },
        async updateService(editedService, service_id) {

            const body = {
                heading: editedService.heading,
                description: editedService.description,
                content: editedService.content,
                images : editedService.images
            }
            const { data, status } = await apiClient(
                `/service/${service_id}`,
                "PATCH",
                JSON.stringify(body)
            );

            if (status !== 201) throw new Error(data.info);

            //update in store
            let objIndex = this.services.findIndex(
                (service) => service._id === editedService._id
            );
            this.services[objIndex] = data.service;

            return true;
        },
        async getImagesOfSingleService(service_id) {
            const { data, status } = await apiClient(`/service/${service_id}`, "get");

            if (status !== 200) throw new Error(data.info);

            this.imagesOfFetchedSingleService = data.images;
        },

    },
});
