import { defineStore } from "pinia";
import apiClient from "@/apiClient";
const orderString = localStorage.getItem("order") || "null";
const orderObject = JSON.parse(orderString);

export default defineStore("order", {
  state: () => ({
    currentOrder: orderObject || [],
    myOrders: [],
  }),

  actions: {
    async createOrder(order) {
      this.currentOrder = order;
      localStorage.setItem("order", JSON.stringify(this.currentOrder));
    },

    async getMyOrders() {
      const { data } = await apiClient("/orders", "get");

      this.myOrders = data;
    },

    async placeOrder(order) {
      const { data, status } = await apiClient(
        "/orders",
        "post",
        JSON.stringify(order)
      );

      if (status !== 201) throw new Error(data.info);

      this.myOrders.unshift(data.order);

      if (data.url) {
        window.location.href = data.url;
      }

      return data;
    },
  },
});
