export default {
  install: (app, options) => {
    // inject a globally available $viewport() method
    const viewport = () => {
      if (window.matchMedia("(max-width: 600px)").matches) return "xs";
      if (
        window.matchMedia("(min-width: 601px) and (max-width: 960px)").matches
      )
        return "sm";
      if (
        window.matchMedia("(min-width: 961px) and (max-width: 1263px)").matches
      )
        return "md";
      if (
        window.matchMedia("(min-width: 1264px) and (max-width: 1904px)").matches
      )
        return "lg";
      return "xl";
    };

    app.config.globalProperties.$viewport = viewport;
    app.provide("viewport", viewport());
  },
};
