<template>
  <div class="dropdown">
    <div
      id="categoryButton"
      class="my-3 d-flex justify-content-between"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#categoryCollapse"
      aria-expanded="false"
      aria-controls="collapse"
    >
      Shop
      <font-awesome-icon icon="fa-solid fa-chevron-down" />
    </div>
    <div id="categoryCollapse" class="collapse">
      <div class="row p-3">
        <div
          class="pb-3 mb-2"
          @click="getSubcategories($event, category._id)"
          :key="category._id"
          v-for="category in allCategories"
        >
          <div
            type="button"
            :data-bs-target="`#subcategoryCollapse${category._id}`"
            aria-expanded="false"
            data-bs-toggle="collapse"
            class="collapse d-flex justify-content-between mb-3"
          >
            {{ category.name }}
            <font-awesome-icon icon="fa-solid fa-chevron-down" />
          </div>
          <div class="collapse ms-2" :id="`subcategoryCollapse${category._id}`">
            <ul class="list-unstyled">
              <li
                class="pb-3 my-3"
                :key="subcategory._id"
                v-for="subcategory in displayedSubcategories"
              >
                <div>
                  <router-link
                    @click="emit('navigate')"
                    :to="`/products/${subcategory._id}`"
                    class="text-decoration-none text-dark"
                  >
                    {{ subcategory.name }}
                    <span
                      style="font-size: 12px"
                      class="ms-1 rounded text-white bg-success px-1"
                    >
                      {{ subcategory.numberOfProducts }}
                    </span>
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";

//stores
import useCategoryStore from "@/stores/categoryStore";
import useSubcategoryStore from "@/stores/subcategoryStore";

const { categories } = storeToRefs(useCategoryStore());
const { getSubcategoriesByCategory } = storeToRefs(useSubcategoryStore());
const { getAllCategories } = useCategoryStore();
const { getAllSubcategories } = useSubcategoryStore();

//data
const allCategories = ref([]);
const displayedSubcategories = ref([]);

//emits
const emit = defineEmits(["navigate"])

//hooks
onMounted(async () => {
  getAllCategories("numberOfSubcategories").then(() => {
    allCategories.value = categories.value.filter((category) => {
      return category.numberOfSubcategories >= 1
    });
  });

  await getAllSubcategories();
});

//methods
const getSubcategories = (event, categoryId) => {
  displayedSubcategories.value = getSubcategoriesByCategory.value(categoryId);
  event.stopPropagation();
};
</script>
  
  <style scoped>
#dropdownMenu {
  width: 500px;
}

#dropdownMenu li:hover {
  cursor: pointer;
}

#categoryButton:focus {
  border: none;
}

#categoryButton {
  border: none;
}
</style>
  