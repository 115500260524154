<template>
  <div id="footer" class="bg-color text-light pt-4">
    <div class="container">
      <div class="row">
        <div class="col mb-4">
          <h1>COMPANY INFORMATION</h1>
          <div class="my-2">BAFREDO Electronics Limited</div>
          <div class="my-2">P. O. Box 33761</div>
          <router-link
            to="/about-us#map"
            class="text-decoration-none text-light d-block"
            >Dar es Salaam, Tanzania</router-link
          >
          <a
            href="tel:+255747637665"
            class="my-2 text-decoration-none text-light d-block"
            >+255 (0) 746 624 875</a
          >
          <a
            href="tel:+255222460157"
            class="my-2 text-decoration-none text-light d-block"
            >+255-22-246 0157</a
          >
          <a
            href="mailto:info@bafredo.co.tz"
            class="my-2 text-decoration-none text-light d-block"
            >info@bafredo.co.tz</a
          >
        </div>

        <div class="col mb-4">
          <h1>COMPANY INFORMATION</h1>
          <div class="my-2">Country of registration : Tanzania</div>
          <div class="my-2">Registration Agency :
            <a href="https://www.brela.go.tz/" target="_blank" class="text-decoration-none">BRELLA</a>
          </div>
          <div class="my-2">
            TIN NUMBER :
            <router-link
                to="/static/tin_number.pdf"
                target="_blank"
                style="cursor: pointer;"
                class="certificate text-decoration-none">
              135-644-951
            </router-link>
          </div>
          <div class="my-2">
            Certificate of incorporation
            <router-link
                to="/static/certificate_of_incorporation.pdf"
                target="_blank"
                style="cursor: pointer;"
                class="certificate text-decoration-none">
              120435
            </router-link>
          </div>
          <router-link
            to="/about-us#map"
            class="text-decoration-none text-light d-block"
            >Dar es Salaam, Tanzania
          </router-link>
          <a
            href="tel:+255747637665"
            class="my-2 text-decoration-none text-light d-block"
            >+255 (0) 746 624 875</a
          >
          <a
            href="tel:+255222460157"
            class="my-2 text-decoration-none text-light d-block"
            >+255-22-246 0157</a
          >
          <a
            href="mailto:info@bafredo.co.tz"
            class="my-2 text-decoration-none text-light d-block"
            >info@bafredo.co.tz</a
          >
        </div>

        <div class="col">
          <h1>POLICIES</h1>
          <div class="d-flex row">
            <!-- <router-link to="/blog" class="my-1 text-decoration-none text-white router-link">Blog</router-link> -->
            <router-link
              to="/privacy-policy"
              class="my-1 text-decoration-none text-white router-link"
              >Privacy policy</router-link
            >
            <router-link
              to="/terms-and-conditions"
              class="my-1 text-decoration-none text-white router-link"
              >Terms & Conditions</router-link
            >
          </div>
          <div class="col mt-4">
            <h1>SUPPORT</h1>
            <div class="d-flex row">
              <router-link
                to="/faqs"
                class="my-1 text-decoration-none text-white router-link"
                >FAQ’s</router-link
              >
            </div>
            <div class="d-flex row">
              <router-link
                  to="/about-us"
                  class="my-1 text-decoration-none text-white router-link"
              >About Us</router-link
              >
            </div>
          </div>
        </div>

        <div class="col">
          <h1>FOLLOW US</h1>
          <div class="d-flex">
            <div class="me-2 fs-2">
              <a href="https://www.facebook.com/bafredonics/">
                <font-awesome-icon icon="fa-brands fa-facebook" />
              </a>
            </div>
            <div class="mx-2 fs-2">
              <a
                href="https://www.youtube.com/channel/UC601Ys2fPPwhkmyhdp9MrhQ?view_as=subscriber"
              >
                <font-awesome-icon
                  icon="fa-brands fa-youtube"
                  style="color: red"
                />
              </a>
            </div>
            <div class="mx-2 fs-2">
              <a
                href="https://www.linkedin.com/company/bafredo-electronics-limited/"
              >
                <font-awesome-icon icon="fa-brands fa-linkedin" />
              </a>
            </div>
            <div class="mx-2 fs-2">
              <a href="https://www.instagram.com/bafredoelectronics/">
                <font-awesome-icon
                  icon="fa-brands fa-instagram"
                  style="color: deeppink"
                />
              </a>
            </div>
            <div class="mx-2 fs-2">
              <a href="https://twitter.com/bafredonics">
                <font-awesome-icon icon="fa-brands fa-twitter" />
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div
    class="bottom-nav pt-2 d-flex justify-content-center align-items-center text-white"
  >
    <p>
      Copyright {{ new Date().getFullYear() }} BAFREDO Electronics Limited. All
      Rights Reserved.
    </p>
  </div>
</template>

<style scoped>
.bg-color {
  background-color: #272727;
}
.bottom-nav {
  background-color: #373636;
}

.router-link:hover {
  color: #3b579d !important;
  text-decoration: underline !important;
}

@media print {
  #footer {
    display: none;
  }
}

.certificate{
  cursor: pointer;
}
.certificate:hover {
  text-decoration: underline;
}
</style>
<script setup>

</script>