<template>
  <div class="input-group search-bar position-relative">
    <input
      @focus="resultsFocused = true"
      @focusout="focusedOut"
      @input="debouncedSearch"
      v-model="searchQuery"
      type="text"
      class="form-control border-0 no-outline"
      placeholder="Please search here ..."
    />
    <button
      @click="navigateToProducts"
      type="button"
      class="input-group-text bg-success text-white search-text px-lg-5"
    >
      Search
    </button>
    <div
      v-if="searchResults.length !== 0 && resultsFocused"
      class="rounded-2 z-3000 p-3 position-absolute bg-white w-100 shadow search-results"
    >
      <div
        @click="navigateToProduct(result._id)"
        :key="result.productId"
        v-for="result of displayedSearchResults"
        class="d-flex justify-content-between"
      >
        <p @click="navigateToProduct(result._id)">{{ result._id }}</p>
        <p class="text-grey" @click="navigateToProduct(result._id)">
          {{ result.subcategory.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
//imports
import { ref, computed } from "vue";
import apiClient from "@/apiClient";
import { useRoute, useRouter } from "vue-router";
import uniqueSelector from "@/helpers/uniqueSelector";
import debounce from "lodash/debounce";

//stores
import useProductStore from "@/stores/productStore";
import useNotificationStore from "@/stores/notificationStore";

const { pushProductToStore } = useProductStore();
const { showNotification } = useNotificationStore();

//data
const searchQuery = ref("");
const resultsFocused = ref(false);
const searchResults = ref([]);
const displayedSearchResults = computed(() => searchResults.value.slice(0, 6));

//router
const router = useRouter();
const route = useRoute();

const focusedOut = () => {
  setTimeout(() => (resultsFocused.value = false), 1000);
};

const navigateToProducts = async () => {
  if (searchQuery.value === "") return;

  if (route.path.includes("products")) {
    router.push({
      path: `/search`,
      query: { search: searchQuery.value },
    });
  } else {
    router.push({
      path: `/products/all`,
      query: { search: searchQuery.value },
    });
  }

  const modal = document.getElementById("searchModal");

  if (!modal) return;
  const searchModal = bootstrap.Modal.getInstance(modal);
  searchModal.hide();

  const { data } = await apiClient(
    `/searches`,
    "POST",
    JSON.stringify({ query: searchQuery.value })
  );
};

const navigateToProduct = async (id) => {
  try {
    console.log('navigating')
    await pushProductToStore(id);

    const modal = document.getElementById("searchModal");

    if (modal){
    const searchModal = bootstrap.Modal.getInstance(modal);
    searchModal.hide();
    }
    
    await router.push(`/product/${id}`);
    searchQuery.value = "";
  } catch (error) {
    error.name = "";
    const notificationObject = {
      isSuccess: false,
      header: "Failed",
      text: error.toString(),
    };
    showNotification(notificationObject);
  }
};

const debouncedSearch = debounce(async () => {
  if (searchQuery.value === "") {
    searchResults.value = [];
    return;
  }

  const { data } = await apiClient(
    `/search?query=${searchQuery.value}&limit=20`
  );

  searchResults.value = uniqueSelector(data);
}, 700);
</script>

<style scoped>
.search-results {
  margin-top: 0.5rem;
  top: 100%;
}

.search-results div {
  cursor: pointer;
}
.search-bar {
  /* border: 1.3px solid rgba(196, 202, 205, 0.4); */
  border-radius: 57px;
  max-width: 475px !important;
}

.search-text {
  border-radius: 0 57px 57px 0 !important;
  border: none;
}
</style>
