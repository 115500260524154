import { defineStore } from "pinia";

export default defineStore("notification", {
  state: () => ({
    header: "",
    text: "",
    isSuccess: false,
  }),

  actions: {
    showNotification(notification) {
      this.header = notification.header;
      this.text = notification.text;
      this.isSuccess = notification.isSuccess;

      const toastLiveExample = document.getElementById("notificationToast");

      const toast = new bootstrap.Toast(toastLiveExample);

      toast.show();
    },
  },
});
