import apiClient from "@/apiClient";
import { defineStore } from "pinia";
import useAuthStore from "./authStore";

export default defineStore("cart", {
  state: () => ({
    cart: [],
  }),
  actions: {
    addToCart(image, name, price, _id) {
      const productIndex = this.cart.findIndex(
        (product_obj) => product_obj.product._id === _id
      );
      if (productIndex === -1) {
        //add to cart
        const cartItem = { product: { _id, name, price, image }, quantity: 1 };
        this.cart.push(cartItem);
      }
      //otherwise increment quantity in cart
      if (this.cart[productIndex])
        this.cart[productIndex].quantity = this.cart[productIndex].quantity + 1;

      localStorage.setItem("cart", JSON.stringify(this.cart));
    },

    async postCart(cart) {
      const body = {
        cartItems: cart.map((item) => ({
          _id: item.product._id,
          quantity: item.quantity,
        })),
      };

      const { data, status } = await apiClient(
        "/carts",
        "post",
        JSON.stringify(body)
      );

      if (status !== 201) throw new Error(data.info);

    },

    clearCart() {
      this.cart = [];
      localStorage.removeItem("cart");
    },

    removeFromCart(_id) {
      this.cart = this.cart.filter((item) => item.product._id !== _id);
      localStorage.setItem("cart", JSON.stringify(this.cart));
    },

    incrementQuantity(_id) {
      const productIndex = this.cart.findIndex(
        (product_obj) => product_obj.product._id === _id
      );
      this.cart[productIndex].quantity = this.cart[productIndex].quantity + 1;
      localStorage.setItem("cart", JSON.stringify(this.cart));
    },

    decrementQuantity(_id) {
      const productIndex = this.cart.findIndex(
        (product_obj) => product_obj.product._id === _id
      );
      if (this.cart[productIndex].quantity > 1)
        this.cart[productIndex].quantity = this.cart[productIndex].quantity - 1;

      localStorage.setItem("cart", JSON.stringify(this.cart));
    },

    getCartItems() {
      const cartString = localStorage.getItem("cart") || "null";
      const cart = JSON.parse(cartString);

      if (cart) {
        this.cart = cart;
      }
    },
  },
  getters: {
    computedCartPrice(state) {
      return state.cart.reduce((subTotal, item) => {
        subTotal += item.product.price * item.quantity;
        return subTotal;
      }, 0);
    },
  },
});
