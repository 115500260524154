<template>
  <nav
    class="navbar navbar-expand-md navbar-light bg-secondary d-none d-md-block"
    id="nav_top"
  >
    <div class="container text-light">
      <div class="d-flex w-100 justify-content-between">
        <div class="d-flex justify-content-between">
          <div class="me-3 d-inline">
            <font-awesome-icon
              class="text-success-pale"
              icon="fa-solid fa-phone"
              rotation="90"
              size="xs"
            />
            <a
              class="fs-10 text-light text-decoration-none ms-1"
              href="tel:+255747637665"
              >+255 (0) 746 624 875</a
            >
          </div>
          <div class="me-3 d-inline">
            <img
              src="@/assets/icons/circled_envelop.png"
              alt="email"
              srcset=""
            />
            <a
              class="fs-10 text-light text-decoration-none ms-1"
              href="mailto:info@bafredo.co.tz"
              >info@bafredo.co.tz</a
            >
          </div>
          <div class="d-inline me-3">
            <img
              src="@/assets/icons/user_location.png"
              alt="location"
              srcset=""
            />
            <router-link
              to="/about-us#map"
              class="fs-10 text-light text-decoration-none ms-1"
              >Dar es salaam, Tanzania</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
