<template>
  <div>
    <div class="mx-1 product-card">
<!--      <div-->
<!--        class="card"-->
<!--        @click="-->
<!--          () => {-->
<!--            let encoded_name = name.toString().replace(/%/g, '%25');-->

<!--            router.push(`/product/${encodeURIComponent(encoded_name)}`);-->
<!--          }-->
<!--        "-->
<!--      >-->
      <div class="card" @click=" () => { router.push(`/product/${name}`)}">
        <img :src="image?.sm" class="card-img-top object-cover" :alt="name" />
        <div class="card-body">
          <p
            class="sub-fs-sm card-text text-center text-uppercase text-truncate my-1 my-md-2 small"
          >
            {{ subcategory }}
          </p>
          <h5
            class="name-fs-sm card-title text-center text-uppercase text-truncate small"
          >
            {{ name }}
          </h5>
          <h5 class="name-fs-sm card-title d-flex justify-content-center small">
            <span
              :class="[
                discount > 0 &&
                  'small text-muted text-decoration-line-through me-1',
              ]"
              >{{ formattedPrice }}</span
            >
            <span v-if="discount > 0">{{ formattedDiscount }}</span>
          </h5>

          <div class="justify-content-center align-items-center d-none">
            <div class="line"></div>
            <IconButton icon="fa-solid fa-star" />
            <IconButton icon="fa-solid fa-star" />
            <IconButton icon="fa-solid fa-star" />
            <IconButton icon="fa-regular fa-star" />
            <IconButton icon="fa-regular fa-star" />
            <div class="line"></div>
          </div>
          <div class="my-1 d-none d-md-flex justify-content-center">
            <IconButton
              @click.stop="
                addProductToWishlist(
                  image,
                  name,
                  price - price * (discount / 100),
                  productId
                )
              "
              class="px-3"
              icon="fa-regular fa-heart"
              title="Add to Wishlist"
            />
            <IconButton
              class="px-3"
              icon="fa-regular fa-eye"
              title="View Product"
            />
          </div>
        </div>
      </div>
      <div
        id="addToCartBox"
        class="justify-content-center d-flex"
        @click="
          addProductToCart(
            image,
            name,
            price - price * (discount / 100),
            productId
          )
        "
      >
        <div
          class="add-to-cart-box d-md-flex justify-content-center align-items-center py-2 d-none"
        >
          <div
            class="inner-white-box rounded-pill bg-white d-flex justify-content-center pt-1 small"
          >
            <font-awesome-icon
              class="text-success me-1"
              icon="fa-solid fa-cart-shopping"
            />
            <p class="small">ADD TO CART</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import moneyFormatter from "@/helpers/moneyFormatter";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

import IconButton from "@/components/atoms/buttons/IconButton.vue";
import useCartStore from "@/stores/cartStore";
import useWishlistStore from "@/stores/wishlistStore";
import useNotificationStore from "@/stores/notificationStore";
import useCurrencyStore from "@/stores/currencyStore";

const { showNotification } = useNotificationStore();
const { currencyData } = storeToRefs(useCurrencyStore());

const props = defineProps({
  _id: {
    type: String,
    required: true,
  },
  productId: {
    type: String,
    required: true,
  },
  name: String,
  image: Object,
  subcategory: String,
  price: Number,
  discount: Number,
});

//data
const formattedPrice = computed(() => {
  return moneyFormatter(props.price, currencyData.value);
});

const formattedDiscount = computed(() => {
  return moneyFormatter(
    props.price - props.price * (props.discount / 100),
    currencyData.value
  );
});

//router
const router = useRouter();

const { addToCart } = useCartStore();

const { addToWishlist } = useWishlistStore();

const addProductToCart = (image, name, price, productId) => {
  const notificationObject = {
    isSuccess: true,
    header: "Success",
    text: "Product added to cart",
  };
  showNotification(notificationObject);

  addToCart(image, name, price, productId);
};

const addProductToWishlist = (image, name, price, productId) => {
  const notificationObject = {
    isSuccess: true,
    header: "Success",
    text: "Product added to Wishlist",
  };
  showNotification(notificationObject);

  addToWishlist(image, name, price, productId);
};
</script>

<style scoped>
.card-text {
  font-size: 12px;
}

.card {
  border-radius: 0 !important;
}

.product-card:hover .card {
  border-color: #246345;
  border-width: 3px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.line {
  height: 1px;
  width: 50%;
  background-color: gray;
}

#addToCartBox {
  display: none;
}

.add-to-cart-box {
  width: 100%;
  display: none;
  /* height: 30px; */
  background-color: #272727;
  transform: translateY(-40px) scale(0);
  transition: all 0.3s ease;
}

.inner-white-box {
  width: 70%;
  height: 80%;
}

p {
  font-size: 12px;
}
img {
  height: 180px;
}
@media only screen and (min-width: 600px) {
  .inner-white-box:hover {
    cursor: pointer;
    color: white !important;
    background-color: #246345 !important;
  }
  .inner-white-box:hover svg {
    color: white !important;
  }
  .product-card:hover #addToCartBox {
    display: flex;
  }

  .product-card:hover .add-to-cart-box {
    display: flex !important;
    transform: translateY(0px) scale(1);
  }
}
@media screen and (max-width: 623px) {
  img {
    height: 180px;
  }
  .name-fs-sm {
    font-size: 0.7rem !important;
  }
  .sub-fs-sm {
    font-size: 0.5rem !important;
  }
}
</style>
