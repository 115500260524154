<template>
  <form @submit.prevent="forgotPassword" class="p-md-5 p-3 pt-md-3">
    <p class="fs-5 text-center mb-5">
      We will send a link to reset your password to your email
    </p>
    <div class="mb-5">
      <label :class="[v$.email.$error && 'text-danger', 'form-label fs-6']"
        >EMAIL</label
      >
      <input
        v-model="data.email"
        type="email"
        class="form-control no-outline"
        id="emailInput"
      />
      <span class="mt-3 text-danger fs-6" v-if="v$.email.$error">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
        {{ v$.email.$errors[0].$message }}
      </span>
    </div>

    <button
      :disabled="loading"
      type="submit"
      class="zoom btn bg-success text-white w-100 mb-4"
    >
      <div v-if="loading" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span v-else>SEND EMAIL</span>
    </button>

    <a
      @click="navigateToRegister"
      class="text-decoration-none d-block text-center mb-3"
      ><span class="text-dark">Don't have an account? </span>Create your account
      here</a
    >
    <a @click="navigateToLogin" class="text-decoration-none d-block text-center"
      >Return to the login page</a
    >
  </form>
</template>

<script setup>
//imports
import { storeToRefs } from "pinia";
import { ref } from "vue";
import apiClient from "@/apiClient.js";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, email, minLength } from "@vuelidate/validators";

//store
import useNavigationStore from "@/stores/navigationStore";
import useNotificationStore from "@/stores/notificationStore";

const { authFormState } = storeToRefs(useNavigationStore());
const { showNotification } = useNotificationStore();

//data

const data = ref({
  email: "",
});
const loading = ref(false);

const rules = {
  email: {
    required: helpers.withMessage("Email is required", required),
    email: helpers.withMessage("Enter a valid Email", email),
  },
};

const v$ = useVuelidate(rules, data);

//methods
const navigateToLogin = () => {
  authFormState.value = "login";
};
const navigateToRegister = () => {
  authFormState.value = "register";
};

const forgotPassword = async () => {
  const body = { email: data.value.email };

  try {
    //validate form using vuelidate
    v$.value.$validate();

    if (!v$.value.$error) {
      loading.value = true;
      const { data, status } = await apiClient(
        "/password/reset",
        "post",
        JSON.stringify(body)
      );

      if (status !== 200) throw new Error(data.info);
    }
  } catch (error) {
    error.name = "";
  } finally {
    const notificationObject = {
      isSuccess: true,
      header: "",
      text: "If the information provided is in our system, you will receive an email notification with instructions to reset the password.",
    };
    showNotification(notificationObject);
    loading.value = false;

    //close model
    const modal = document.getElementById("formModal");
    const formModal = bootstrap.Modal.getInstance(modal);
    formModal.hide();

    authFormState.value = "login";
  }
};
</script>

<style scoped>
.btn,
.form-control {
  border-radius: 50px !important;
}
</style>
