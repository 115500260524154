<template>
  <section>
    <div class="d-flex my-2 justify-content-between">
      <div class="d-flex justify-content-between">
        <img
            :src="image.sm"
            width="80"
            height="80"
            class="object-contain"
            alt=""
        />

        <div class="ms-4">
          <div>{{ name }}</div>
          <div class="fw-bold text-success">{{ formattedPrice }}</div>
          <button @click="addToWishlistItemToCart(image,name,price,_id)"
                  class="bg-success text-white btn mt-2"
                  style="font-size: 10px"
          >
            Add To Cart
          </button>
        </div>
      </div>
      <div class="d-flex">
        <!-- <div class="d-flex align-items-center border-danger fw-bold text-danger border-success text-success text-dark
                    border-dark p-1 border border-2 text-center"
             style="font-size: 10px; height: 25%"
        >
          IN-STOCK
        </div> -->
        <button @click="removeFromWishlist(_id)" style="height: 4px;width: 2px" type="button" class="btn-close text-reset bg-white ms-2"></button>
      </div>
    </div>
    <div class="line mt-2"></div>
  </section>

</template>

<script setup>
import {storeToRefs} from "pinia/dist/pinia";
import {computed} from "vue";

import useCartStore from "@/stores/cartStore";
import useWishlistStore from "@/stores/wishlistStore";
import moneyFormatter from "@/helpers/moneyFormatter";
import useNotificationStore from "@/stores/notificationStore";
import useCurrencyStore from "@/stores/currencyStore";

const { removeFromWishlist }  = useWishlistStore()
const { addToCart } = useCartStore()
const { showNotification } = useNotificationStore();
const {currencyData} = storeToRefs(useCurrencyStore());


let props = defineProps({
  _id:{
    type:String,
    required:true
  },
  name: String,
  price: Number,
  image: Object,
})

const notificationObject = {
  isSuccess: true,
  header: "Success",
  text: "Item added to cart",
};

const addToWishlistItemToCart = (image,name,price,_id)=>{
  addToCart(image,name,price,_id)
  //show notification
  showNotification(notificationObject);
}

const formattedPrice = computed(() => {
  return moneyFormatter(props.price, currencyData.value)
});
</script>

<style scoped>
.line {
  height: 1px;
  width: 100%;
  background-color: gray;
  opacity: 0.4;
}
</style>