<template>
  <div class="d-flex">
    <img
      src="@/assets/icons/logo.png"
      width="39"
      height="39"
      alt=""
      srcset=""
    />
    <div class="logo-text d-none d-md-inline-block">
      <div class="logo-top">
        <span class="main-Logo fw-bolder text-success-pale">BAFREDO</span
        ><span class="fw-lighter">Electronics</span>
        <span class="text-success-pale">.</span>
      </div>
      <div class="logo-bottom ms-2">
        <span>Imagination. Creativity. Innovation.</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.logo-text {
  letter-spacing: 0.2px;
  font-size: 1rem;
  position: relative;
  vertical-align: middle;
}

.logo-top {
  border-bottom: 2.5px solid var(--success-pale);
}

.logo-bottom {
  font-size: 0.5rem;
}

@media print {
  .logo-text{
    display: inline-block!important;
  }
}
</style>
