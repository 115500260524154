import { defineStore } from "pinia";
import apiClient from "@/apiClient";
export default defineStore("partner", {
  state: () => ({
    partners: [],
    latestPartners: [],
    fetchedPartner: "",
    fetchedSinglePartner: {},
    imagesOfFetchedSinglePartner: [],
  }),

  actions: {
    async createPartner(partner) {
      const { data, status } = await apiClient(
        "/partners",
        "post",
        JSON.stringify(partner)
      );

      if (status !== 201) throw new Error(data.info);

      //add the return partner to the (beginning) of the partners array
      this.partners.unshift(data.Partner);

      //console.log(this.partners)

      return true;
    },

    async getSinglePartner(partner_id) {
      const { data, status } = await apiClient(`/partner/${partner_id}`, "get");

      if (status !== 200) throw new Error(data.info);

      this.fetchedSinglePartner = data;
    },
    async deletePartner(id) {
      const { data, status } = await apiClient(`/partner/${id}`, "DELETE");

      if (status !== 201) throw new Error(data.info);

      this.partners = this.partners.filter((partner) => id !== partner._id);
    },
    async getAllPartners() {
      const { data, status } = await apiClient("/partners", "get");

      if (status !== 200) throw new Error(data.info);

      this.partners = data;
    },
    async getLatestPartners() {
      const { data, status } = await apiClient("/latest_partners", "get");

      if (status !== 200) throw new Error(data.info);

      this.latestPartners = data;
    },
    async updatePartner(editedPartner, partner_id) {
      const body = {
        name: editedPartner.name,
        image: editedPartner.image,
      };
      const { data, status } = await apiClient(
        `/partner/${partner_id}`,
        "PATCH",
        JSON.stringify(body)
      );

      if (status !== 201) throw new Error(data.info);

      //update in store
      let objIndex = this.partners.findIndex(
        (partner) => partner._id === editedPartner._id
      );
      this.partners[objIndex] = data.partner;

      return true;
    },
    async getImagesOfSinglePartner(partner_id) {
      const { data, status } = await apiClient(`/partner/${partner_id}`, "get");

      if (status !== 200) throw new Error(data.info);

      this.imagesOfFetchedSinglePartner = data.images;
    },
  },
});
