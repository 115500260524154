<template>
  <div class="dropdown">
    <div
      class="d-flex text-white ps-3 align-items-center"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <img v-if="image" width="40"  height="40" :src="image" class="rounded-circle object-cover me-2"/>
      <font-awesome-icon v-else class="me-2" icon="fa-solid fa-user" />
      <span>{{ username }}</span>
      <font-awesome-icon class="ms-1" icon="fa-solid fa-caret-down" />
    </div>

    <ul class="dropdown-menu">
      <li>
        <router-link class="dropdown-item" to="/profile">Profile</router-link>
      </li>
      <li><a class="dropdown-item" @click="logoutUser">Logout</a></li>
    </ul>
  </div>
</template>

<script setup>
//imports
import { useRouter } from "vue-router";

import useAuthStore from "@/stores/authStore";

defineProps({
  username: String,
  image: String,
});

const { logout } = useAuthStore();

//router
const router = useRouter();

//methods
const logoutUser = () => {
  logout();
  router.push("/");
};
</script>

<style scoped>
.dropdown:hover {
  cursor: pointer;
}
.dropdown-item:focus,
.dropdown-item:active {
  background-color: var(--success);
  color: white;
}
</style>
