import { defineStore } from "pinia";
import { io, Socket } from "socket.io-client";

export default defineStore("socket", {
  state: () => ({
    socketId: "",
    socket: null,
  }),

  actions: {
    connectToSocket(orderId) {
      const socket = io(
        process.env.NODE_ENV === "development"
          ? `localhost:2700`
          : `https://dev.bafredo.co.tz/api`,
        { auth: { _id: orderId } }
      );
      this.socketId = orderId;
      this.socket = socket;
    },
  },
});
