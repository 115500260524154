import { defineStore } from "pinia";
import apiClient from "@/apiClient";

export default defineStore("subcategory", {
  state: () => ({
    subcategories: [],
  }),

  actions: {
    async createSubcategory(subcategory) {
      const body = {
        name: subcategory.name,
        category: subcategory.category._id,
        SKU: subcategory.SKU,
      };

      const { data, status } = await apiClient(
        "/subcategories",
        "post",
        JSON.stringify(body)
      );

      if (status !== 201) throw new Error(data.info);

      //reset password token
      this.subcategories.unshift(data.subcategory);

      return true;
    },
    async getAllSubcategories(query) {
      if (this.subcategories.length !== 0) return;

      const { data, status } = await apiClient("/subcategories", "get");

      if (status !== 200) throw new Error(data.info);

      this.subcategories = data;
    },
    async deleteSubCategory(id) {
      const { data, status } = await apiClient(`/subcategory/${id}`, "DELETE");

      if (status !== 200) throw new Error(data.info);

      this.subcategories = this.subcategories.filter(
        (subcategory) => id !== subcategory._id
      );
    },

    async deleteManySubcategories(subcategoriesToDelete) {
      const { data, status } = await apiClient("/subcategories", "DELETE", JSON.stringify({subcategories: subcategoriesToDelete}));

      if (status !== 200) throw new Error(data.info);

      this.subcategories = this.subcategories.filter(
        (subcategory) => !subcategoriesToDelete.includes(subcategory._id)
      );

      return data.retainedSubcategories
    },
    async editSubCategory(sub_category) {
      const body = {
        category: sub_category.category._id,
        name: sub_category.name,
        SKU: sub_category.SKU
      };
      //update in db
      const { data, status } = await apiClient(
        `/subcategory/${sub_category._id}`,
        "PATCH",
        JSON.stringify(body)
      );

      if (status !== 200) throw new Error(data.info);

      //update in store
      let objIndex = this.subcategories.findIndex(
        (subCategory) => subCategory._id === sub_category._id
      );

      this.subcategories[objIndex] = data.subcategory;

    },
  },

  getters: {
    getSubcategoriesByCategory(state) {
      return (categoryId) =>
        state.subcategories.filter(
          (subcategory) => subcategory?.category?._id === categoryId
        );
    },
  },
});
