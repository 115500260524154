function getServerUrl() {
  let clientUrl;

  if (window.location.hostname === "localhost") {
    clientUrl = "http://localhost:2700";
  } else if (window.location.hostname === "dev.bafredo.co.tz") {
    clientUrl = "http://dev.bafredo.co.tz/api";
  } else if (window.location.hostname === "www.bafredo.co.tz") {
    clientUrl = "https://www.bafredo.co.tz/api";
  } else {
    clientUrl = window.location.origin;
  }

  return clientUrl;
}

export default getServerUrl;