<template>
  <form @submit.prevent="resetUserPassword" class="p-md-5 p-3">
    <div class="mb-3">
      <label
        for="passwordInput"
        :class="[v$.password.$error && 'text-danger', 'form-label fs-6']"
        >PASSWORD</label
      >
      <div
        :class="[
          v$.password.$error && 'border-danger',
          'input-group mx-auto form-control d-flex justify-content-between',
        ]"
      >
        <input
          v-model="data.password"
          :type="showPassword ? 'text' : 'password'"
          class="input border-0 no-outline"
        />
        <div class="input-group-append">
          <font-awesome-icon
            v-if="!showPassword"
            @click="showPassword = true"
            class="passwordVisibilityButton"
            icon="fa-regular fa-eye-slash"
          />
          <font-awesome-icon
            v-else
            @click="showPassword = false"
            id="passwordVisibilityButton"
            icon="fa-regular fa-eye"
          />
        </div>
      </div>
      <PasswordStrength
        v-if="data.password !== ''"
        :password="data.password"
      />
      <span class="mt-3 text-danger fs-6" v-if="v$.password.$error">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
        {{ v$.password.$errors[0].$message }}
      </span>
    </div>
    <div class="mb-4">
      <label
        for="passwordInput"
        :class="[v$.confirmPassword.$error && 'text-danger', 'form-label fs-6']"
        >CONFIRM PASSWORD</label
      >
      <div
        :class="[
          v$.password.$error && 'border-danger',
          'input-group mx-auto form-control d-flex justify-content-between',
        ]"
      >
        <input
          v-model="data.confirmPassword"
          :type="showConfirmPassword ? 'text' : 'password'"
          class="input border-0 no-outline"
        />
        <div class="input-group-append">
          <font-awesome-icon
            v-if="!showConfirmPassword"
            @click="showConfirmPassword = true"
            class="passwordVisibilityButton"
            icon="fa-regular fa-eye-slash"
          />
          <font-awesome-icon
            v-else
            @click="showConfirmPassword = false"
            id="passwordVisibilityButton"
            icon="fa-regular fa-eye"
          />
        </div>
      </div>
      <span class="mt-3 text-danger fs-6" v-if="v$.confirmPassword.$error">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
        Passwords do not match
      </span>
    </div>
    <button
      :disabled="data.loading"
      type="submit"
      class="zoom btn bg-success text-white w-100 mb-4"
    >
      <div v-if="data.loading" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span v-else>RESET PASSWORD</span>
    </button>

    <a @click="navigateToLogin" class="text-decoration-none d-block text-center"
      >Return to the login page</a
    >
  </form>
</template>

<script setup>
//components
import PasswordStrength from "./PasswordStrength.vue";

//imports
import { storeToRefs } from "pinia";
import {useRouter} from "vue-router";
import { ref, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";

//store
import useAuthStore from "@/stores/authStore";
import useNavigationStore from "@/stores/navigationStore";
import useNotificationStore from "@/stores/notificationStore";

const { authFormState } = storeToRefs(useNavigationStore());
const { showNotification } = useNotificationStore();
const { resetPassword } = useAuthStore();

//router
const router = useRouter();

//data
const data = ref({
  password: "",
  confirmPassword: "",
  loading: false,
});

const showPassword = ref(false);
const showConfirmPassword = ref(false);

//validation rules
const validationRules = computed(() => ({
  password: { required, minLength: minLength(8) },
  confirmPassword: { required, sameAs: sameAs(data.value.password) },
}));

const v$ = useVuelidate(validationRules, data.value);

//methods
const navigateToLogin = () => {
  authFormState.value = "login";
};

const resetUserPassword = async () => {
  try {
    //validate form using vuelidate

    v$.value.$validate();

    //if no error then send register request
    if (!v$.value.$error) {
      //call the auth store login method
      data.value.loading = true;
      const registerSuccess = await resetPassword(data.value.password);
      const notificationObject = {
        isSuccess: registerSuccess,
        header: "Success",
        text: "Created new password",
      };

      //show notification
      showNotification(notificationObject);

      //stop loading
      data.value.loading = false;

      router.push("/")

      //navigate state to login
      authFormState.value = "login";

      return;
    }
  } catch (error) {
    error.name = "";
    const notificationObject = {
      isSuccess: false,
      header: "Failed",
      text: error.toString(),
    };
    showNotification(notificationObject);
    data.value.loading = false;
  }
};
</script>

<style scoped>
.btn,
.form-control {
  border-radius: 50px !important;
}

.passwordVisibilityButton {
  cursor: pointer;
}
</style>
