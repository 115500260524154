import getServerUrl from "./helpers/getServerUrl";

export default async (endpoint, method, body) => {
  const authString = localStorage.getItem("authDetails") || "null";
  //parse the string to JSON
  const authObject = JSON.parse(authString);
  const url = getServerUrl();

  const authorization = authObject?.token || "";

  const response = await fetch(
    process.env.NODE_ENV === "development"
      ? `${url}${endpoint}`
      : `${url}${endpoint}`,
    {
      method,
      headers:
        body instanceof FormData
          ? { authorization, Accept: "application/json" }
          : {
              Accept: "application/json",
              "Content-Type": "application/json",
              authorization,
            },
      body,
    }
  );

  const status = response.status;
  const data = await response.json();

  return { data, status };
};
