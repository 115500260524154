import { defineStore } from 'pinia'


export default defineStore('navigation', {
  state: () => ({
    authFormState: "login",
    nextRoute: "",
  }),
  actions: {
    openAuthFormModal(state, nextRoute) {
      this.nextRoute = nextRoute;
      this.authFormState = state;

      const authModal = document.getElementById("formModal");

      const modal = new bootstrap.Modal(authModal);

      modal.show();
    },
  }
})