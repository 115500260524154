<template>
  <header
    class="navbar sticky-top bg-dark flex-md-nowrap p-0 shadow"
    data-bs-theme="dark"
  >
    <router-link
      to="/"
      class="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 text-white"
    >
      <CompanyLogo />
    </router-link>
    <UserDetails
    class="me-md-2 me-lg-3"
      :username="user?.username"
      :image="user?.profileImage?.sm"
    />

    <ul class="navbar-nav flex-row d-md-none">
      <li class="nav-item text-nowrap">
        <div
          class="nav-link px-3 text-white bg-dark"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <svg class="bi">
            <use xlink:href="#list" />
          </svg>
        </div>
      </li>
    </ul>
  </header>

  <div class="container-fluid">
    <div class="row">
      <DashboardSideBar />

      <NotificationToast />

      <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div class="scrollable-content">
          <router-view></router-view>
        </div>
      </main>
    </div>
  </div>
  <footer class="py-4 bg-light mt-auto">
    <div class="container-fluid px-4">
      <div class="d-flex align-items-center justify-content-between small">
        <div class="text-muted">
          Copyright &copy; bafredo.co.tz {{ new Date().getFullYear() }}
        </div>
        <div>
          <router-link to="/privacy-policy" href="#"
            >Privacy Policy</router-link
          >
          &middot;
          <router-link to="/terms-and-conditions" href="#"
            >Terms &amp; Conditions</router-link
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import DashboardSideBar from "@/components/atoms/sidebars/DashboardSideBar.vue";
import NotificationToast from "@/components/atoms/toasts/NotificationToast.vue";
import CompanyLogo from "@/components/atoms/CompanyLogo.vue";
import UserDetails from "@/components/atoms/UserDetails.vue";

import { storeToRefs } from "pinia";


import useAuthStore from "@/stores/authStore";

const { user } = storeToRefs(useAuthStore());

</script>

<style scoped>
.scrollable-content {
  max-height: calc(100vh - 20px);
  overflow-y: auto;
}

.bi {
  display: inline-block;
  width: 1rem;
  height: 1rem;
}

.nav-item {
  padding: 5px 0;
  color: white;
}

.nav-item:hover {
  background-color: #246344;
}

.nav-link {
  color: black;
}

.nav-link:hover {
  color: white;
}

/*
 * Sidebar
 */

@media (min-width: 768px) {
  .sidebar .offcanvas-lg {
    position: -webkit-sticky;
    position: sticky;
    top: 48px;
  }

  .navbar-search {
    display: block;
  }
}

.sidebar .nav-link {
  font-size: 0.875rem;
  font-weight: 500;
}

.sidebar .nav-link.active {
  color: #246344;
}

.sidebar .nav-link.active:hover {
  color: white;
}

.sidebar-heading {
  font-size: 0.75rem;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .form-control {
  padding: 0.75rem 1rem;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}

.bd-mode-toggle {
  z-index: 1500;
}

.bd-mode-toggle .dropdown-menu .active .bi {
  display: block !important;
}
</style>

