import { defineStore } from "pinia";
import apiClient from "@/apiClient";
const authString = localStorage.getItem("authDetails") || "null";
const authObject = JSON.parse(authString);

export default defineStore("banner", {
  state: () => ({
    allBanners: [],
    homeBanners: [],
    fetchedBanner: {},
  }),

  actions: {
    async createBanner(banner) {
      const formData = new FormData();
      //compulsary data
      formData.append("background", banner.background);
      formData.append("link", banner.link);
      formData.append("button", JSON.stringify(banner.button));
      formData.append("title", JSON.stringify(banner.title));
      formData.append("subtitle", JSON.stringify(banner.subtitle));
      formData.append("images", banner.images);

      const { data, status } = await apiClient("/banners", "post", formData);

      if (status !== 201) throw new Error(data.info);

      //reset password token
      this.allBanners.unshift(data.banner);

      return true;
    },
    async updateBanner(banner, banner_id) {
      const body = {
        title: banner.title,
        subtitle: banner.subtitle,
        background: banner.background,
        link: banner.link,
        button: banner.button,
      };

      const { data, status } = await apiClient(
        `/banner/${banner_id}`,
        "PATCH",
        JSON.stringify(body)
      );

      if (status !== 200) throw new Error(data.info);

      //update in store
      let objIndex = this.allBanners.findIndex(
        (the_banner) => the_banner._id === banner_id
      );
      this.allBanners[objIndex] = data.banner;

      return true;
    },
    async updateBannerImage(file, banner_id) {
      // fieldName is the name of the input field
      // file is the actual file object to send
      const formData = new FormData();
      formData.append("images", file);

      //send request to backend
      const { data, status } = await apiClient(
        `/banners/${banner_id}/image`,
        "PATCH",
        formData
      );

      if (status !== 200) throw new Error(data.info);

      //update in store
      let objIndex = this.allBanners.findIndex(
        (the_banner) => the_banner._id === banner_id
      );

      if (objIndex < 0) objIndex = 0;
      this.allBanners[objIndex] = data.banner;

      return true;
    },

    async getAllBanners() {
      const { data, status } = await apiClient("/banners", "get");

      if (status !== 200) throw new Error(data.info);

      this.allBanners = data;
    },
    // async getHomeBanners() {
    //   this.homeBanners = this.allBanners.filter(
    //     (banner) => banner.type === "home"
    //   );

    //   if (this.homeBanners.length !== 0) return;

    //   const { data, status } = await apiClient("/banners", "get");

    //   if (status !== 200) throw new Error(data.info);

    //   this.homeBanners = data;
    // },
    async deleteBanner(id) {
      const { data, status } = await apiClient(`/banner/${id}`, "DELETE");

      if (status !== 200) throw new Error(data.info);

      this.allBanners = this.allBanners.filter((banner) => id !== banner._id);
    },
    async getSingleBanner(banner_id) {
      const foundBanner = this.allBanners.find((banner) => {
        return banner._id === banner_id;
      });

      if (foundBanner) {
        this.fetchedBanner = foundBanner;
        return;
      }

      const { data, status } = await apiClient(`/banner/${banner_id}`, "get");

      if (status !== 200) throw new Error(data.info);

      this.fetchedBanner = data;
    },
  },
});
