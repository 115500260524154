<template>
  <!-- start main_nav -->
  <nav class="navbar navbar-expand-sm navbar-dark bg-dark py-3">
    <div
      class="container d-flex justify-content-between align-items-center g-0"
    >
      <div class="d-flex align-items-center">
        <button
          class="bg-inherit btn btn-sm border-0 d-inline d-md-none"
          data-bs-toggle="offcanvas"
          data-bs-target="#sideNav"
          aria-controls="sideNav"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <router-link
          class="align-self-center text-light text-decoration-none"
          to="/"
        >
          <CompanyLogo />
        </router-link>
      </div>
      <ProductSearch class="d-none d-lg-flex" />
      <div class="d-flex mt-3 mt-md-0">
        <button
          type="button"
          class="btn zoom nav-icon d-lg-none d-flex align-items-center text-light me-2 no-outline"
          data-bs-toggle="modal"
          data-bs-target="#searchModal"
        >
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
        </button>
        <div
          role="button"
          class="zoom nav-icon d-flex align-items-center position-relative text-light text-decoration-none me-3"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasWishlist"
          aria-controls="offcanvasRight"
        >
          <font-awesome-icon icon="fa-solid fa-heart" />
          <span
            class="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-success"
          >
            {{ wishlist.length }}
          </span>
        </div>

        <div
          v-if="$viewport() === 'lg' || $viewport() === 'xl'"
          class="d-flex mx-2"
        >
          <div class="d-flex align-items-center bg-secondary text-light px-2">
            {{ formattedComputedCartPrice }}
          </div>
          <div
            role="button"
            class="btn bg-success rounded-0 zoom nav-icon d-flex align-items-center text-decoration-none text-light d-inline-block position-relative"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <font-awesome-icon icon="fa-solid fa-cart-shopping" />
            <span
              class="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-success"
            >
              {{ cart.length }}
            </span>
          </div>
        </div>

        <div
          v-else
          role="button"
          class="me-2 mx-md-2 zoom nav-icon d-flex align-items-center text-decoration-none text-light d-inline-block position-relative"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <font-awesome-icon icon="fa-solid fa-cart-shopping" />
          <span
            class="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-success"
          >
            {{ cart.length }}
          </span>
        </div>

        <router-link
          to="/dashboard/"
          v-if="user?.accountType === 'admin'"
          class="zoom btn btn-sm bg-success text-white ms-3 me-1 d-flex align-items-center"
        >
          DASHBOARD
        </router-link>

        <button
          v-if="!isAuthenticated"
          class="zoom btn btn-sm bg-success text-white ms-3 me-1"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#formModal"
        >
          Login | Register
        </button>
        <UserDetails
          v-else
          :username="user?.username"
          :image="user?.profileImage?.sm"
        />
      </div>
    </div>
  </nav>
  <!-- End main_nav -->
</template>

<script setup>
//imports
import { computed } from "vue";
import { storeToRefs } from "pinia";
import moneyFormatter from "@/helpers/moneyFormatter";

//components
import CompanyLogo from "../atoms/CompanyLogo.vue";
import UserDetails from "../atoms/UserDetails.vue";

import useAuthStore from "@/stores/authStore";
import ProductSearch from "../atoms/products/ProductSearch.vue";
import useWishlistStore from "@/stores/wishlistStore";
import useCartStore from "@/stores/cartStore";
import useCurrencyStore from "@/stores/currencyStore";

const { wishlist } = storeToRefs(useWishlistStore());
const { cart, computedCartPrice } = storeToRefs(useCartStore());
const { isAuthenticated, user } = storeToRefs(useAuthStore());
const { currencyData } = storeToRefs(useCurrencyStore());

const formattedComputedCartPrice = computed(() => {
  return moneyFormatter(computedCartPrice.value, currencyData.value);
});
</script>

<style scoped>
@media print {
  .navbar {
    display: none;
  }
}
</style>
