import { createApp } from "vue";
import { createPinia } from "pinia";
import PrimeVue from 'primevue/config';
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";

// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";

import App from "./App.vue";
import router from "./router";
import breakpoints from "./plugins/breakpoints";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import brand icons */
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);

/* import specific icons */
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import DashboardLayout from "@/views/layouts/DashboardLayout.vue";

/* add icons to the library */
library.add(fas);
library.add(far);

const pinia = createPinia();
// register Swiper custom elements
register();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(breakpoints);
app.use(PrimeVue);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
