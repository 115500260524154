<template>
  <form @submit.prevent="editUserProfile" class="p-md-5 p-3">
    <div class="mb-3">
      <label
        :class="[v$.username.$error && 'text-danger', 'form-label fs-6']"
        >USERNAME</label
      >
      <input
        type="text"
        v-model="data.username"
        :class="[
          v$.username.$error && 'border-danger',
          'form-control no-outline',
        ]"
      />
      <span class="mt-3 text-danger fs-6" v-if="v$.username.$error">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
        {{ v$.username.$errors[0].$message }}
      </span>
    </div>
    <div class="mb-3">
      <label
        :class="[v$.email.$error && 'text-danger', 'form-label fs-6']"
        >EMAIL</label
      >
      <input
        v-model="data.email"
        type="email"
        :class="[v$.email.$error && 'border-danger', 'form-control no-outline']"
      />
      <span class="mt-3 text-danger fs-6" v-if="v$.email.$error">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
        {{ v$.email.$errors[0].$message }}
      </span>
    </div>
    <button
      :disabled="data.loading"
      type="submit"
      class="zoom btn bg-success text-white w-100 mb-4"
    >
      <div v-if="data.loading" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span v-else>EDIT PROFILE</span>
    </button>

  </form>
</template>

<script setup>
//imports
import { storeToRefs } from "pinia";
import { ref, computed, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import {required, email, minLength, sameAs, helpers} from "@vuelidate/validators";

//store
import useAuthStore from "@/stores/authStore";
import useNavigationStore from "@/stores/navigationStore";
import useNotificationStore from "@/stores/notificationStore";

const { authFormState } = storeToRefs(useNavigationStore());
const { showNotification } = useNotificationStore();
const { user } = storeToRefs(useAuthStore());
const { register, editProfile } = useAuthStore();

//data
const data = ref({
  email: "",
  username: "",
  loading: false,
});

//validation rules
const validationRules = computed(() => ({
  username: { required: helpers.withMessage('Username is required', required) },
  email: {
    required: helpers.withMessage('Email is required', required),
    email: helpers.withMessage('Enter a valid Email', email),
  },
}));

const v$ = useVuelidate(validationRules, data.value);

//hooks
onMounted(()=> {
  data.value.email = user.value.email;
  data.value.username = user.value.username;
})

//methods
const navigateToLogin = () => {
  authFormState.value = "login";
};

const editUserProfile = async () => {
  try {
    const userDetails = {
    username: data.value.username,
    email: data.value.email,
  };

    //validate form using vuelidate

    v$.value.$validate();

    //if no error then send register request
    if (!v$.value.$error) {
      //call the auth store login method
      data.value.loading = true;
      await editProfile(userDetails);
      const notificationObject = {
        isSuccess: true,
        header: "Success",
        text: "Updated user profile",
      };

      //show notification
      showNotification(notificationObject);

      //stop loading
      data.value.loading = false;

      //navigate to email verification
      authFormState.value = "login";

      //close model
      const modal = document.getElementById("formModal");
      const formModal = bootstrap.Modal.getInstance(modal);
      formModal.hide();

      return;
    }
  } catch (error) {
    error.name = "";
    const notificationObject = {
      isSuccess: false,
      header: "Failed to update user profile",
      text: error.toString(),
    };
    showNotification(notificationObject);      
    authFormState.value = "login";

    data.value.loading = false;
  }
};
</script>

<style scoped>
.btn,
.form-control {
  border-radius: 50px !important;
}
</style>
