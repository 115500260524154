import apiClient from "@/apiClient";
import { defineStore } from "pinia";

export default defineStore("wishlist", {
  state: () => ({
    wishlist: [],
  }),
  actions: {
    addToWishlist(image, name, price, _id) {
      const productIndex = this.wishlist.findIndex(
        (product_obj) => product_obj.product._id === _id
      );
      if (productIndex === -1) {
        //add to wishlist
        const wishlistItem = {
          product: { _id, name, price, image },
          quantity: 1,
        };
        this.wishlist.push(wishlistItem);
      }
      //otherwise increment quantity in wishlist
      if (this.wishlist[productIndex]) {
        this.wishlist[productIndex].quantity =
          this.wishlist[productIndex].quantity + 1;
      }

      localStorage.setItem("wishlist", JSON.stringify(this.wishlist));
    },

    clearWishlist() {
      this.wishlist = [];
      localStorage.removeItem("wishlist");
    },

    decrementQuantity(_id) {
      const productIndex = this.wishlist.findIndex(
        (product_obj) => product_obj.product._id === _id
      );
      if (this.wishlist[productIndex].quantity > 1)
        this.wishlist[productIndex].quantity =
          this.wishlist[productIndex].quantity - 1;

      localStorage.setItem("wishlist", JSON.stringify(this.wishlist));
    },

    getWishlistItems() {
      const wishlistString = localStorage.getItem("wishlist") || "null";
      const wishlist = JSON.parse(wishlistString);

      if (wishlist) {
        this.wishlist = wishlist;
      }
    },

    incrementQuantity(_id) {
      const productIndex = this.wishlist.findIndex(
        (product_obj) => product_obj.product._id === _id
      );
      this.wishlist[productIndex].quantity =
        this.wishlist[productIndex].quantity + 1;
      localStorage.setItem("wishlist", JSON.stringify(this.wishlist));
    },

    removeFromWishlist(_id) {
      this.wishlist = this.wishlist.filter((item) => item.product._id !== _id);
      localStorage.setItem("wishlist", JSON.stringify(this.wishlist));
    },

    async postWishlist(wishlist) {
      const body = {
        wishlistItems: wishlist.map((item) => ({
          _id: item.product._id,
        })),
      };
      const { data, status } = await apiClient(
        "/wishlists",
        "post",
        JSON.stringify(body)
      );

      if (status !== 201) throw new Error(data.info);

    },
  },
  getters: {},
});
