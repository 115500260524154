<template>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasWishlist"
    aria-labelledby="offcanvasRightLabel"
  >
    <div class="offcanvas-header bg-success text-white">
      <h5 id="offcanvasRightLabel">WISHLIST</h5>
      <button
        type="button"
        class="btn-close text-reset bg-white"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body" v-if="wishlist.length !== 0">
      <WishlistProductCard
        v-for="wishlistItem in wishlist"
        :key="wishlistItem.product._id"
        :_id="wishlistItem.product._id"
        :name="wishlistItem.product.name"
        :price="wishlistItem.product.price"
        :image="wishlistItem.product.image"
      />
      <button
        @click="clearWishlist"
        class="btn bg-light-grey border-0 no-outline mt-5 float-end"
      >
        Clear Wishlist
      </button>
    </div>

    <div class="offcanvas-body" v-else>
      <h1>Wishlist is empty</h1>
    </div>
  </div>
</template>

<script setup>
//imports
import { storeToRefs } from "pinia/dist/pinia";
import { onMounted } from "vue";


//components
import WishlistProductCard from "@/components/atoms/products/WishlistProductCard.vue";

//stores
import useWishlistStore from "@/stores/wishlistStore";

const { wishlist } = storeToRefs(useWishlistStore());
const { getWishlistItems, clearWishlist } = useWishlistStore();

//hooks
onMounted(() => {
  getWishlistItems();
});
</script>

<style scoped></style>
