<template>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel"
  >
    <div class="offcanvas-header bg-success text-white">
      <h5 id="offcanvasRightLabel">SHOPPING CART</h5>
      <button
        type="button"
        class="btn-close text-reset bg-white"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body" v-if="cart.length !== 0">
      <MiniProductDetailsCard
        v-for="cartItem in cart"
        :key="cartItem.product._id"
        :_id="cartItem.product._id"
        :name="cartItem.product.name"
        :quantity="cartItem.quantity"
        :price="cartItem.product.price"
        :image="cartItem.product.image"
      />
      <div class="d-flex justify-content-between my-2">
        <h5 class="fw-bold">SUBTOTAL:</h5>
        <h5 class="fw-bold text-success">
          {{ formattedCartPrice }}
        </h5>
      </div>
      <div class="line mt-2"></div>
      <button
        @click="createCartOrder"
        data-bs-dismiss="offcanvas"
        class="bg-success text-white btn my-2"
        style="width: 100%"
      >
        CHECKOUT
      </button>

      <button
        @click="clearCart"
        class="btn bg-light-grey border-0 no-outline mt-5 float-end"
      >
        Clear Cart
      </button>
    </div>

    <div class="offcanvas-body" v-else><h1>Cart is Empty</h1></div>
  </div>
</template>

<script setup>
//imports
import { storeToRefs } from "pinia/dist/pinia";
import { useRouter } from "vue-router";
import { onMounted, computed } from "vue";
import moneyFormatter from "@/helpers/moneyFormatter";

//components
import MiniProductDetailsCard from "@/components/atoms/products/MiniProductDetailsCard.vue";

//stores
import useOrderStore from "@/stores/orderStore";
import useCartStore from "@/stores/cartStore";
import useCurrencyStore from "@/stores/currencyStore";

const { cart, computedCartPrice } = storeToRefs(useCartStore());
const { getCartItems, clearCart } = useCartStore();
const { createOrder } = useOrderStore();
const { currencyData } = storeToRefs(useCurrencyStore());

//router
const router = useRouter();

const formattedCartPrice = computed(() => {
  return moneyFormatter(computedCartPrice.value, currencyData.value);
});

//hooks
onMounted(() => {
  getCartItems();
});
//methods
const createCartOrder = () => {
  const order = cart.value;
  createOrder(order);
  router.push("/checkout");
};
</script>

<style scoped></style>
