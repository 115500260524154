<template>
  <TopNav />
  <div class="sticky-top">
    <NavBar />
    <ProductsNav />
  </div>
  <div id="breadcrumb"></div>
  <CartSidebar />
  <WishListSideBar />
  <NotificationToast />
  <FormModal />
  <SideNav />
  <MobileSearchModal v-if="$viewport() === 'xs' || $viewport() === 'sm'" />

  <main>
    <router-view></router-view>
  </main>

  <Footer />
  <a
      href="https://api.whatsapp.com/send/?phone=255746624875&text&type=phone_number&app_absent=0"
      class="float"
      target="_blank"
  >
    <font-awesome-icon icon="fa-brands fa-whatsapp" class="my-float" />
  </a>
</template>

<script setup>
import { storeToRefs } from "pinia";

import CompanyLogo from "../../components/atoms/CompanyLogo.vue";
import FormModal from "../../components/global/FormModal.vue";
import Footer from "../../components/global/Footer.vue";
import MobileSearchModal from "../../components/global/MobileSearchModal.vue";
import NavBar from "../../components/global/NavBar.vue";
import NotificationToast from "@/components/atoms/toasts/NotificationToast.vue";
import ProductsNav from "../../components/global/ProductsNav.vue";
import TopNav from "../../components/global/TopNav.vue";
import SideNav from "../../components/global/SideNav.vue";
import CartSidebar from "@/components/atoms/sidebars/CartSideBar.vue";
import WishListSideBar from "@/components/atoms/sidebars/WishListSideBar.vue";

//store
import useSocketStore from "@/stores/socketStore";
const { socketId, socket } = storeToRefs(useSocketStore());

// //socket events
// socketConnection(socketId).on("payment-status", (res) => {
//   console.log(res)
// })
</script>

<style>
:root {
  --dark: #272727;
  --grey: #969090;
  --light-grey: #d9d9d9;
  --secondary: #373636;
  --success: #246345;
  --success-pale: #78a55a;
  --swiper-theme-color: #000;
  font-family: "Montserrat", sans-serif;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

.bg-dark {
  background-color: var(--dark) !important;
}

.bg-grey {
  background-color: var(--grey);
}

.bg-light-smoke {
  background-color: #fffefe;
}
.bg-light-grey {
  background-color: var(--light-grey) !important;
}
.bg-inherit {
  background: inherit;
}

.bg-success {
  background-color: var(--success) !important;
}
.bg-success-pale {
  background-color: var(--success-pale) !important;
}
.bg-secondary {
  background-color: var(--secondary) !important;
}

.text-grey {
  color: var(--grey);
}
.text-light-grey {
  color: var(--light-grey);
}

.text-success {
  color: var(--success) !important;
}

.text-success-pale {
  color: var(--success-pale) !important;
}

a {
  color: #798cee;
}
.fs-10 {
  font-size: 0.685rem;
  font-weight: 600;
}

/*** Effects *****/
/**decorations***/
/*
*These are css effects on decorations found in the website
*/
/** Hover effect ****/
/*
*These are css effects on links and buttons when hovered
*/
.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--success-pale);
}
.z-3000 {
  z-index: 3000 !important;
}
.zoom,
a {
  transition: transform 0.2s ease-in-out;
}

.zoom:hover {
  transform: scale(1.05);
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}

/* a:hover {
  font-size: 18px;
  font-size-adjust: 20px;
} */

/*** Imagery ****/
/*
*These styles how an image covers its parent contain
**/
.object-cover {
  object-fit: cover;
}

.object-contain {
  object-fit: contain;
}

/**Typography */
h1,
.h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2rem;
}

h3,
.h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.25rem;
}

.xl-font {
  font-size: 2.5rem !important;
  line-height: 1.3;
}

.pointer {
  cursor: pointer;
}
@media only screen and (max-width: 991px) {
  .xl-font {
    font-size: 2rem !important;
    line-height: 1.3;
  }
}
@media only screen and (max-width: 1400px) {
  .xl-font {
    font-size: 1.6rem !important;
    line-height: 1.3;
  }
}

/**custom inputs */
.checkbox {
  cursor: pointer;
}

.form-check-input:checked {
  background-color: var(--success);
  border: none;
}

@media print {
  .my-float, .float {
    display: none !important;
  }
}
</style>
