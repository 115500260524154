const moneyFormatter = (price, currencyData) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyData?.currency || "TZS",
    maximumFractionDigits: currencyData?.maximumFractionDigits || 0, // (causes 2500.99 to be printed as $2,501)
  });

  const formattedPrice = formatter.format(price);

  return formattedPrice
};

export default moneyFormatter;
