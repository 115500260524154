<template>
  <div class="p-md-5 p-3 pt-md-3">
    <p class="fs-5 text-center mb-5">
      We sent a verification link to your email
    </p>
    <p>Did not receive email?</p>
    <button
      :disabled="loading"
      type="button"
      @click="requestEmailVerification"
      class="zoom btn bg-success text-white w-100 mb-4"
    >
      <div v-if="loading" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span v-else>RESEND EMAIL</span>
    </button>
    <a @click="navigateToLogin" class="text-decoration-none d-block text-center"
      >Return to the login page</a
    >
  </div>
</template>

<script setup>
//imports
import { storeToRefs } from "pinia";
import { ref } from "vue";
import apiClient from "@/apiClient.js";

//store
import useAuthStore from "@/stores/authStore";
import useNavigationStore from "@/stores/navigationStore";
import useNotificationStore from "@/stores/notificationStore";

const { authFormState } = storeToRefs(useNavigationStore());
const {user} = storeToRefs(useAuthStore());
const { showNotification } = useNotificationStore();

//data
const loading = ref(false);

//methods
const navigateToLogin = () => {
  authFormState.value = "login";
};

const requestEmailVerification = async () => {
  try {
    loading.value = true;
    const body = { email: user.value.email };
    const { data, status } = await apiClient(
      "/email/request",
      "post",
      JSON.stringify(body)
    );

    if (status !== 200) throw new Error(data.info);

    const notificationObject = {
      isSuccess: data.success,
      header: "Success",
      text: "Email sent successfully",
    };
    showNotification(notificationObject);
    loading.value = false;

  } catch (error) {
    error.name = "";
    const notificationObject = {
      isSuccess: false,
      header: "Failed",
      text: error.toString(),
    };
    showNotification(notificationObject);
    loading.value = false;
  }
};
</script>

<style scoped>
.btn,
.form-control {
  border-radius: 50px !important;
}
</style>
