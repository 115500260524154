import { defineStore } from "pinia";
import apiClient from "@/apiClient";
export default defineStore("category", {
  state: () => ({
    categories: [],
    fetchedCategory: "",
  }),

  actions: {
    async createCategory(category) {
      const { data, status } = await apiClient(
        "/categories",
        "post",
        JSON.stringify(category)
      );

      if (status !== 201) throw new Error(data.info);

      //reset password token
      this.categories.unshift(data.category);

      return true;
    },
    async deleteCategory(id) {
      const { data, status } = await apiClient(`/category/${id}`, "DELETE");

      if (status !== 200) throw new Error(data.info);

      this.categories = this.categories.filter(
        (category) => id !== category._id
      );
    },
    async getAllCategories(orderBy) {
      if (this.categories.length !== 0) return;

      const { data, status } = await apiClient(
        `/categories?orderBy=${orderBy}`,
        "get"
      );

      if (status !== 200) throw new Error(data.info);

      this.categories = data;
    },

    async deleteManyCategories(categoriesToDelete) {
      const { data, status } = await apiClient(
        "/categories",
        "DELETE",
        JSON.stringify({ categories: categoriesToDelete })
      );

      if (status !== 200) throw new Error(data.info);

      this.categories = this.categories.filter(
        (category) => !categoriesToDelete.includes(category._id)
      );
    },

    async editCategory(editedCategory) {
      const body = { name: editedCategory.name, SKU: editedCategory.SKU };

      //update in db
      const { data, status } = await apiClient(
        `/category/${editedCategory._id}`,
        "PATCH",
        JSON.stringify(body)
      );

      if (status !== 200) throw new Error(data.info);

      //update in store
      let objIndex = this.categories.findIndex(
        (category) => category._id === editedCategory._id
      );
      this.categories[objIndex] = data.category;
    },
  },
});
