<template>
  <div class="container" v-if="loading">
    <div class="row g-0">
      <ProductPlaceholder
        class="mb-3 col-lg-2 col-md-3 col-6 g-0"
        :key="n"
        v-for="n in slidesPerView"
      />
    </div>
  </div>
  <div class="position-relative" v-else>
    <div
      class="h-100 d-none d-md-flex align-items-center position-absolute top-50 start-0 translate-middle me-2"
    >
      <button
        @click="slidePrev()"
        class="me-2 shadow btn btn small text-dark bg-white rounded-circle"
      >
        <font-awesome-icon icon="fa-solid fa-chevron-left" />
      </button>
    </div>
    <div>
      <swiper-container
        :class="[`product-slider-${productsType}`, 'my-3']"
        :slides-per-view="slidesPerView"
        :slides-per-group="slidesPerView"
        :loop="true"
        :space-between="0"
      >
        <swiper-slide :key="product._id" v-for="product in products">
          <ProductCard
            :productId="product.productId"
            :_id="product._id"
            :name="product._id"
            :subcategory="product.subcategory.name"
            :price="product.minPrice"
            :image="product.displayImage"
            :discount="product.discount"
          />
        </swiper-slide>
      </swiper-container>
    </div>
    <div class="d-flex h-100 d-none d-md-flex align-items-center position-absolute top-50 start-100 translate-middle">
      <button
        @click="slideNext()"
        class="ms-2 shadow btn btn small text-dark bg-white rounded-circle"
      >
        <font-awesome-icon icon="fa-solid fa-chevron-right" />
      </button>
    </div>
  </div>
</template>

<script setup>
//imports
import { onMounted, ref, inject } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

//components
import ProductCard from "@/components/atoms/products/ProductCard.vue";
import ProductPlaceholder from "@/components/atoms/products/ProductPlaceholder.vue";

//store
import useProductStore from "@/stores/productStore";

const { getRandomProducts, getNewProducts, getBestSellingProducts } =
  useProductStore();
const { randomProducts, newProducts, bestSellingProducts } = storeToRefs(
  useProductStore()
);

//router
const router = useRouter();

//props
const props = defineProps({
  productsType: { type: String, required: true, default: "randomProducts" },
});

//data
const breakpoint = inject("viewport");
const loading = ref(true);
const products = ref([]);
const slidesPerView = ref(2);

//hooks
onMounted(async () => {
  breakpoint === "xs"
    ? (slidesPerView.value = 2)
    : breakpoint === "sm"
    ? (slidesPerView.value = 4)
    : (slidesPerView.value = 6);

  if (props.productsType === "newArrivals") {
    getNewProducts(0, 24).then(() => {
      products.value = newProducts.value;
      loading.value = false;
    });
    return;
  }

  if (props.productsType === "random") {
    getRandomProducts(24).then(() => {
      products.value = randomProducts.value;
      loading.value = false;
    });
    return;
  }

  if (props.productsType === "bestSelling") {
    getBestSellingProducts(0, 24).then(() => {
      products.value = bestSellingProducts.value;
      loading.value = false;
    });
    return;
  }
});

//methods
const slideNext = () => {
  const swiper = document.querySelector(
    `.product-slider-${props.productsType}`
  ).swiper;
  swiper.slideNext();
};

const slidePrev = () => {
  const swiper = document.querySelector(
    `.product-slider-${props.productsType}`
  ).swiper;

  swiper.slidePrev();
};
</script>
