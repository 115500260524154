<template>
  <button
    @click="() => login()"
    :disabled="loading"
    type="button"
    class="zoom btn btn-primary w-100"
  >
    <div v-if="loading" class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="d-flex align-items-center justify-content-center" v-else>
      <img src="@/assets/icons/facebook.svg" />
      <span class="ms-2">Continue with Facebook</span>
    </div>
  </button>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import getServerUrl from "@/helpers/getServerUrl"

//store
import useNotificationStore from "@/stores/notificationStore";

const { showNotification } = useNotificationStore();

//router
const router = useRouter();

//data
const loading = ref(false);

//methods
const login = async () => {
  try {
    loading.value = true;
    const endpoint = "/auth/facebook";
    const serverUrl = getServerUrl();

    console.log(serverUrl)

    window.location.href = `${serverUrl}${endpoint}`;
  } catch (error) {
    error.name = "";
    showNotification({
      isSuccess: false,
      header: "Failed",
      text: error.toString(),
    });
  } finally {
    loading.value = false;
  }
};
</script>

<style></style>
