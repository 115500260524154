<template>
  <div class="border pb-4">
    <div style="height: 210px" class="w-100 placeholder-glow">
      <span class="placeholder h-100 col-12"></span>
    </div>
    <div class="card-body">
      <p class="card-text placeholder-glow text-center">
        <span class="placeholder col-5 placeholder-xs "></span>
        <span class="placeholder col-8 placeholder-sm"></span>
        <span class="placeholder col-5 placeholder-sm"></span>
      </p>
    </div>
  </div>
</template>