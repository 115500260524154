<template>
  <div
      class="offcanvas offcanvas-start"
      tabindex="-1"
      id="sideNav"
      aria-labelledby="offcanvasExampleLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title"></h5>
      <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <ul class="list-unstyled">
        <router-link
            @click="closeCanvas"
            class="text-decoration-none text-dark"
            to="/"
        >
          <li class="py-3">Home</li>
        </router-link
        >
        <router-link
            v-if="isAuthenticated"
            @click="closeCanvas"
            class="text-decoration-none text-dark"
            to="/orders"
        >
          <li class="py-3">Orders</li>
        </router-link
        >
        <router-link
            @click="closeCanvas"
            class="text-decoration-none text-dark"
            :to="user?.accountType === 'admin' ? '/products' : '/products/all'"
        >
          <li class="py-3">Products</li>
        </router-link>


<!--        <router-link-->
<!--            v-if="user?.accountType === 'admin'"-->
<!--            @click="closeCanvas"-->
<!--            to="/categories"-->
<!--            class="text-decoration-none text-dark"-->
<!--        >-->
<!--          <li class="py-3">Categories</li>-->
<!--        </router-link-->
<!--        >-->

        <CategoriesCollapse  v-if="!(user?.accountType === 'admin')" @navigate="closeCanvas"/>


<!--        <router-link-->
<!--            @click="closeCanvas"-->
<!--            class="text-dark text-decoration-none"-->
<!--            to="/subcategories"-->
<!--            v-if="user?.accountType === 'admin'"-->
<!--        >-->
<!--          <li class="py-3">Subcategories</li>-->
<!--        </router-link>-->

<!--        <router-link-->
<!--            v-if="user?.accountType === 'admin'"-->
<!--            @click="closeCanvas"-->
<!--            class="text-decoration-none text-dark"-->
<!--            to="/banners"-->
<!--        >-->
<!--          <li class="py-3">Banners</li>-->
<!--        </router-link-->
<!--        >-->

<!--        <router-link-->
<!--            class="text-dark text-decoration-none"-->
<!--            to="/shipping_methods"-->
<!--            v-if="user?.accountType === 'admin'"-->
<!--        >-->
<!--          <li class="py-3">Shipping methods</li>-->
<!--        </router-link>-->


        <div class="dropdown py-1">
          <div
              id="categoryButton"
              class="my-3 d-flex justify-content-between"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#supportCollapse"
              aria-expanded="false"
              aria-controls="collapse"
          >
            Support
            <font-awesome-icon icon="fa-solid fa-chevron-down" />
          </div>
          <div id="supportCollapse" class="collapse">
            <div class="row px-3">
              <router-link
                  @click="closeCanvas()"
                  class="text-decoration-none text-dark"
                  to="/blogs"
              >
                <li class="py-3">Blog</li>
              </router-link>
              <router-link
                  @click="closeCanvas()"
                  class="text-decoration-none text-dark"
                  to="/contact-us"
              >
                <li class="py-3">Contact Us</li>
              </router-link>
            </div>
          </div>
        </div>


        <div class="dropdown py-1">
          <div
              id="servicesButton"
              class="my-3 d-flex justify-content-between"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#servicesCollapse"
              aria-expanded="false"
              aria-controls="collapse"
          >
            Services
            <font-awesome-icon icon="fa-solid fa-chevron-down" />
          </div>
          <div id="servicesCollapse" class="collapse">
            <div class="row px-3" v-for="service in services" v-if="services.length !== 0" :key="service.id">
              <router-link
                  @click="closeCanvas()"
                  class="text-decoration-none text-dark"
                  :to="`/service/${service._id}`"
              >
                <li class="py-3">{{ service.heading }}</li>
              </router-link>

            </div>
          </div>
        </div>




      </ul>
    </div>
  </div>
</template>

<script setup>
//imports
import {storeToRefs} from "pinia";

//components
import CategoriesCollapse from "./CategoriesCollapse.vue";
//store
import useAuthStore from "@/stores/authStore";

const {isAuthenticated, user} = storeToRefs(useAuthStore());

const closeCanvas = () => {
  //close off canvas
  const offcanvas = document.getElementById("sideNav");
  const sideNav = bootstrap.Offcanvas.getInstance(offcanvas);
  sideNav.hide();
  //push to the specified link
};


import useServiceStore from "@/stores/serviceStore";
import {onMounted, ref} from "vue";


const {getAllServices} = useServiceStore();
const {services} = storeToRefs(useServiceStore());

const fetchingData = ref(true)


onMounted(async () => {
  await getAllServices();

  fetchingData.value = false;
})


</script>

<style scoped>
.offcanvas {
  max-width: 300px;
}
</style>
