<template>
  <BannerPlaceholder v-if="allBanners?.length < 1" />
  <swiper-container
    v-else
    :navigation="$viewport() !== 'xs' && true"
    :slides-per-view="1"
    :slides-per-group="1"
    :autoplay="{
      delay: 5000,
      pauseOnMouseEnter: true,
    }"
    :loop="true"
  >
    <swiper-slide :key="banner._id" v-for="banner in allBanners">
      <HeadBanner
        :buttonBackground="banner.button.background"
        :buttonColor="banner.button.color"
        :buttonText="banner.button.text"
        :subtitleColor="banner.subtitle.color"
        :titleColor="banner.title.color"
        :background="banner.background"
        :link="banner.link || '/'"
        :title="banner.title.text"
        :subtitle="banner.subtitle.text"
        :image="banner.image.md || ''"
      />
    </swiper-slide>
  </swiper-container>

  <div class="container my-md-5 g-0">
    <div class="row mt-2 g-0">
      <div class="col">
        <div class="col-sm-10 ms-2">
          <h1>OUR PRODUCTS</h1>
        </div>
      </div>
      <div class="ms-2 row my-2 d-none d-md-block g-0">
        <p>
          BAFREDO Electronics offers quality and affordable products and
          services to hobbyists and practitioners in electronics. We put
          customers at the center of all our innovations, and ensure that they
          get satisfied with our products and services.
        </p>
      </div>
      <ProductsSlider productsType="random" />
    </div>
    <div class="row mt-md-2 g-0">
      <div class="row g-0">
        <div class="col">
          <div class="col-sm-10 ms-2">
            <h1>NEW ARRIVALS</h1>
          </div>
        </div>
      </div>
      <div class="ms-2 row my-2 d-none d-md-block g-0">
        <div>
          We are always ahead of technology, striving for innovations that bring
          positive impact to the society.
        </div>
      </div>
      <ProductsSlider productsType="newArrivals" />
    </div>
    <div class="row mt-2 g-0">
      <div class="row g-0">
        <div class="col">
          <div class="col-sm-10 ms-2">
            <h1>HOT-SELLING PRODUCTS</h1>
          </div>
        </div>
      </div>
      <div class="ms-2 row my-2 d-none d-md-block g-0">
        <div>
          BAFREDO Electronics manufactures and supplies a range of innovative
          products that have received significant attention to many customers.
          Our pride is to see the products meet your aspiration.
        </div>
      </div>
      <ProductsSlider productsType="bestSelling" />
    </div>
    <div class="d-flex justify-content-center align-items-center my-3">
      <router-link to="/products/all" class="bg-success text-white btn">
        <span>VIEW MORE</span>
      </router-link>
    </div>
    <!-- <div class="row mt-2">
      <div class="row">
        <div class="col">
          <div class="col-sm-10 h1 fs-4">PROJECTS AND TUTORIALS</div>
        </div>
      </div>
      <div class="row">
        <div class="col d-none d-md-block g-0">
          <div>
            You can develop interesting projects from electronic components
            available from our store. This section discusses sample projects and
            gives tutorials on how to realize the projects in the real-world
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card my-3" style="width: 21rem">
            <img
              src="../../assets/images/phone.png"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">COURSE ON MOBILE PHONE</h5>
              <p class="card-text">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </p>
              <a href="#" class="btn btn-success px-3 opacity-75">Read More</a>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card my-3" style="width: 21rem">
            <img
              src="../../assets/images/phone.png"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">COURSE ON MOBILE PHONE</h5>
              <p class="card-text">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </p>
              <a href="#" class="btn btn-success px-3 opacity-75">Read More</a>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card my-3" style="width: 21rem">
            <img
              src="../../assets/images/phone.png"
              class="card-img-top"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">COURSE ON MOBILE PHONE</h5>
              <p class="card-text">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </p>
              <a href="#" class="btn btn-success px-3 opacity-75">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <PartnersList class="mt-4" />
  </div>
  <!-- <NewsLetter /> -->
</template>

<script setup>
//imports
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

//components
import HeadBanner from "@/components/client/home/HeadBanner.vue";
import BannerPlaceholder from "@/components/client/home/BannerPlaceholder.vue";
import NewsLetter from "@/components/global/NewsLetter.vue";
import ProductsSlider from "@/components/client/home/ProductsSlider.vue";
import PartnersList from "@/components/client/home/PartnersList.vue";
//store
import useBannerStore from "@/stores/bannerStore";

const { getAllBanners } = useBannerStore();
const { allBanners } = storeToRefs(useBannerStore());

//router
const router = useRouter();

//data
const slide = ref(1);

//hooks
onMounted(async () => {
  getAllBanners();
});
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .overflow-x-scroll {
    overflow-x: scroll;
  }
  h1 {
    font-size: 0.9rem;
  }
}

.active {
  border-bottom: 2px solid var(--success);
  color: #246345;
  font-weight: bold;
}
</style>
