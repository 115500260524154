<template>
  <div class="mt-5">
    <h1 class="mb-2 text-center">OUR PARTNERS</h1>

    <div
      v-if="partners.length <= 3"
      class="thumbnail text-center mt-3 d-flex align-items-center justify-content-start"
    >
      <div :key="partner.name" v-for="partner in partners" class="mx-3">
        <img
          height="130"
          width="130"
          :src="partner?.image.md"
          class="card-img-top object-cover zoom"
          :alt="partner.name"
          @click="navigateToPartnerPage(partner.link)"
        />
        <div class="card-body mb-3">
          <h5
            class="name-fs-sm card-title fs-6 text-truncate d-inline-block"
            style="max-width: 100px"
          >
            {{ partner.name }}
          </h5>
        </div>
      </div>
    </div>
    <div class="position-relative" v-else>
      <div
        class="h-100 d-none d-md-flex align-items-center position-absolute top-50 start-0 translate-middle me-2"
      >
        <button
          @click="slidePrev()"
          class="me-2 shadow btn btn small text-dark bg-white rounded-circle"
        >
          <font-awesome-icon icon="fa-solid fa-chevron-left" />
        </button>
      </div>
      <div>
        <swiper-container
          :class="[`partners-slider`, 'my-3']"
          :slides-per-view="slidesPerView"
          :slides-per-group="slidesPerView"
          :space-between="30"
          :autoplay="{
            delay: 5000,
            pauseOnMouseEnter: true,
          }"
          :loop="true"
        >
          <swiper-slide :key="partner.name" v-for="partner in partners">
            <img
              height="200"
              width="200"
              :src="partner?.image.md"
              class="card-img-top object-cover"
              :alt="partner.name"
            />
            <div class="card-body mb-3">
              <h5
                class="name-fs-sm card-title fs-6 text-truncate d-inline-block"
                style="max-width: 120px"
              >
                {{ partner.name }}
              </h5>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
      <div class="d-flex h-100 d-none d-md-flex align-items-center position-absolute top-50 start-100 translate-middle">
        <button
          @click="slideNext()"
          class="ms-2 shadow btn btn small text-dark bg-white rounded-circle"
        >
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </button>
      </div>
    </div>

    <!-- <div class="container mt-2 d-none d-md-block">
      <div class="float-end">
       
      </div>
    </div> -->
  </div>
</template>

<script setup>
//imports
import { onMounted, ref, inject } from "vue";
import { storeToRefs } from "pinia";

//components

//store
import usePartnerStore from "@/stores/partnerStore";

const { getAllPartners } = usePartnerStore();
const { partners } = storeToRefs(usePartnerStore());

//data
const breakpoint = inject("viewport");
const loading = ref(true);
const slidesPerView = ref(2);

//hooks
onMounted(async () => {
  try {
    breakpoint === "xs"
    ? (slidesPerView.value = 2)
    : breakpoint === "sm"
    ? (slidesPerView.value = 4)
    : (slidesPerView.value = 6);

    getAllPartners();
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
});

//methods
const slideNext = () => {
  const swiper = document.querySelector(`.partners-slider`).swiper;
  swiper.slideNext();
};

const slidePrev = () => {
  const swiper = document.querySelector(`.partners-slider`).swiper;

  swiper.slidePrev();
};

const navigateToPartnerPage = (link) => {
  if (!link) return;

  window.open(link, "_blank");
};
</script>
