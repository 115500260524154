<template>
  <div
    class="modal fade"
    id="formModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content border-0 bg-inherit">
        <div class="modal-body p-0">
          <img
            src="@/assets/icons/logo.png"
            alt="Bafredo electronics"
            width="80"
            height="80"
            class="bg-white mx-auto d-block rounded-circle border border-success p-2"
          />

          <form
            @submit.prevent
            id="authForm"
            class="mt-3 w-100 shadow bg-white"
          >
            <div class="auth-header text-center bg-success text-white py-3">
              <button
                type="button"
                class="bg-inherit border-0 float-end text-white me-4"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
              <p v-if="authFormState === 'login'" class="fs-5">LOGIN</p>
              <p v-if="authFormState === 'changePassword'" class="fs-5">
                CHANGE YOUR PASSWORD
              </p>
              <p v-if="authFormState === 'register'" class="fs-5">
                CREATE YOUR ACCOUNT
              </p>
              <p v-if="authFormState === 'editProfile'" class="fs-5">
                EDIT YOUR PROFILE
              </p>
              <p v-if="authFormState === 'emailVerification'" class="fs-5">
                EMAIL VERIFICATION
              </p>
              <p v-if="authFormState === 'forgotPassword'" class="fs-5">
                FORGOT YOUR PASSWORD?
              </p>
              <p v-if="authFormState === 'resetPassword'" class="fs-5">
                RESET YOUR PASSWORD
              </p>
            </div>
            <ChangePassword v-if="authFormState === 'changePassword'" />
            <EmailVerification v-if="authFormState === 'emailVerification'" />
            <EditProfileForm v-if="authFormState === 'editProfile'" />
            <ForgotPasswordForm v-if="authFormState === 'forgotPassword'" />
            <LoginForm v-if="authFormState === 'login'" />
            <RegisterForm v-if="authFormState === 'register'" />
            <ResetPasswordForm v-if="authFormState === 'resetPassword'" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ChangePassword from "./ChangePassword.vue";
import EditProfileForm from "./EditProfileForm.vue"
import EmailVerification from "./EmailVerification.vue";
import RegisterForm from "./RegisterForm.vue";
import LoginForm from "./LoginForm.vue";
import ForgotPasswordForm from "./ForgotPasswordForm.vue";
import ResetPasswordForm from "./ResetPasswordForm.vue";

import useNavigationStore from "@/stores/navigationStore";
import { storeToRefs } from "pinia";

const { authFormState } = storeToRefs(useNavigationStore());
</script>

<style scoped>
#authForm,
.auth-header {
  border-radius: 50px 50px 0px 0px;
}
.modal-body {
  max-width: 480px;
  /* margin-left: auto;
  margin-right: auto; */
}
</style>
