import { defineStore } from "pinia";
import apiClient from "@/apiClient";

export default defineStore("product", {
  state: () => ({
    allProducts: [],
    allProductsByName: [],
    bestSellingProducts: [],
    newProducts: [],
    productSubcategories: [],
    randomProducts: [],
    relatedProducts: [],
    totalProductsCount: 0,
    visitedProducts: [],
    fetchedSingleProduct: {},
    imagesOfFetchedSingleProduct: [],
  }),

  actions: {
    async createProduct(product) {
      const products = [];

      if (product.attributeNames?.length !== 0) {
        product.attributeValues.forEach((item) => {
          const stock = item[item.length - 1];
          const price = item[item.length - 2];

          //remove price and stock
          // item.pop();
          // item.pop();

          const attributes = [];
          //   item.map((value, index) => ({
          //   name: product.attributeNames[index],
          //   value,
          // }));
          for (let i = 0; i < item.length - 2; i++) {
            attributes.push({
              name: product.attributeNames[i],
              value: item[i],
            });
          }

          const productPush = {
            name: product.name,
            description: product.description,
            subcategory: product.subcategory,
            price: Number(price),
            stock: Number(stock),
            attributes,
            images: product.images,
          };
          products.push(productPush);
        });
      } else {
        product.attributes = [];
        delete product.attributeNames;
        delete product.attributeValues;
        products.push(product);
      }

      const { data, status } = await apiClient(
        "/products",
        "post",
        JSON.stringify(products)
      );

      if (status !== 201) throw new Error(data.info);

      data.products.forEach((product) => {
        this.allProducts.unshift(product);
      });

      return true;
    },
    async deleteManyProducts(productsToDelete) {
      const { data, status } = await apiClient(
        "/products",
        "DELETE",
        JSON.stringify({ products: productsToDelete })
      );

      if (status !== 200) throw new Error(data.info);

      this.allProducts = this.allProducts.filter(
        (product) => !productsToDelete.includes(product._id)
      );
    },

    async deleteProduct(id) {
      const { data, status } = await apiClient(`/product/${id}`, "DELETE");

      if (status !== 200) throw new Error(data.info);

      this.allProducts = this.allProducts.filter(
        (product) => id !== product._id
      );
    },
    async getAllProducts(query) {
      // if (this.allProducts.length !== 0) return;

      const { data, status } = await apiClient("/products", "get");

      if (status !== 200) throw new Error(data.info);

      this.allProducts = data;
    },

    async getAllProductsByName(query) {
      // if (this.allProducts.length !== 0) return;

      const { data, status } = await apiClient(`/products/grouped`);

      if (status !== 200) throw new Error(data.info);

      console.log(data);

      this.allProductsByName = data;
    },

    async getBestSellingProducts(skip = 0, limit = 1000) {
      const { data, status } = await apiClient(
        `/products/grouped?orderBy=orders&skip=${skip}&limit=${limit}`,
        "get"
      );

      if (status !== 200) throw new Error(data.info);

      this.bestSellingProducts = data;
    },

    async getNewProducts(skip = 0, limit = 10000) {
      // if (this.newProducts.length !== 0) return;

      const { data, status } = await apiClient(
        `/products/grouped?skip=${skip}&limit=${limit}`,
        "get"
      );

      if (status !== 200) throw new Error(data.info);

      this.newProducts = data;
    },

    async getProductsBySubcategory(subcategoryId) {
      const foundSubcategory = this.productSubcategories.find(
        (productSubcategory) => productSubcategory.subcategory === subcategoryId
      );

      if (foundSubcategory) return;

      const { data, status } = await apiClient(
        `/products/grouped?subcategory=${subcategoryId}`,
        "get"
      );

      if (status !== 200) throw new Error(data.info);

      this.productSubcategories.push({
        subcategory: subcategoryId,
        products: data,
      });
    },

    async getProductsCount() {
      if (this.totalProductsCount !== 0) return;

      const { data, status } = await apiClient(
        "/products/grouped/count",
        "get"
      );

      if (status !== 200) throw new Error(data.info);

      this.totalProductsCount = data;
    },

    async getRandomProducts(limit = 10000) {
      // if (this.randomProducts.length !== 0) return;

      const { data, status } = await apiClient(
        `/products/grouped?query=random&limit=${limit}`,
        "get"
      );

      if (status !== 200) throw new Error(data.info);

      this.randomProducts = data;
    },

    async pushProductToStore(productId) {
      const foundProduct = this.allProducts.find(
        (product) => product._id === productId
      );

      if (foundProduct) return;

      const { status, data } = await apiClient(
        `/product_grouped?id=${productId}`
      );

      if (status !== 200) throw new Error(data.info);

      this.allProducts.push(data);
    },

    // async updateProduct(product, product_id) {
    //   const body = {
    //     name: product.name,
    //     description: product.description,
    //     price: product.price,
    //     stock: product.stock,
    //     images: product.images,
    //     attributes: product.attributes,
    //     subcategory: product.subcategory,
    //   };

    //   const { data, status } = await apiClient(
    //     `/product/${product_id}`,
    //     "PATCH",
    //     JSON.stringify(body)
    //   );

    //   if (status !== 200) throw new Error(data.info);

    //   //update in store
    //   let objIndex = this.allProducts.findIndex(
    //     (product) => product._id === product_id
    //   );
    //   this.allProducts[objIndex] = data.product;

    //   return true;
    // },

    async updateProduct(product, product_id) {
      const { data, status } = await apiClient(
        `/product_grouped`,
        "PATCH",
        JSON.stringify(product)
      );

      if (status !== 200) throw new Error(data.info);

      //update in store
      let objIndex = this.allProducts.findIndex(
        (product) => product._id === product_id
      );
      this.allProducts[objIndex] = data.product;

      return true;
    },

    async visitProduct(name) {
      const foundVisitedProduct = this.visitedProducts.find((product) => {
        product.name === name;
      });

      if (foundVisitedProduct) return;

      const { data } = await apiClient(`/product_grouped?id=${name}`);
      this.visitedProducts.push({
        name,
        products: data,
        relatedProducts: [],
      });
    },
    async getSingleProduct(product_id) {
      const { data, status } = await apiClient(`/product/${product_id}`, "get");

      if (status !== 200) throw new Error(data.info);

      this.fetchedSingleProduct = data;
    },
    async getImagesOfSingleProduct(product_id) {
      const { data, status } = await apiClient(`/product/${product_id}`);

      if (status !== 200) throw new Error(data.info);

      this.imagesOfFetchedSingleProduct = data.images;
    },
    async getRelatedProducts(name) {
      const { data } = await apiClient(`/product/${name}/related`, "get");

      this.relatedProducts = data;
    },
  },

  getters: {
    getProductsByName: (state) => {
      return (name) =>
        state.allProducts.filter((product) => product.name === name);
    },
  },
});
