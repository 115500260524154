<template>
  <div class="container g-0">
    <div class="row py-4">
      <div style="height: 190px" class="col-5 order-last placeholder-glow">
        <span class="placeholder h-100 col-12"></span>
      </div>
      <div class="col-7 py-3 pe-lg-5 px-xl-5 placeholder-glow">
        <span class="placeholder col-12 placeholder"></span>
        <span class="placeholder col-10 placeholder"></span>
        <span class="placeholder col-3 placeholder-lg mt-5"></span>
      </div>
    </div>
  </div>
</template>