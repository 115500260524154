import { defineStore } from "pinia";
import apiClient from "@/apiClient";
const authString = localStorage.getItem("authDetails") || "null";
const authObject = JSON.parse(authString);

export default defineStore("auth", {
  state: () => ({
    isAuthenticated: authObject ? true : false,
    user: authObject?.user || null,
    token: authObject?.token || "",
    resetPasswordToken: "",
  }),

  actions: {
    async editProfile(userDetails) {
      //send request to backend
      const { data, status } = await apiClient(
        "/profile",
        "PATCH",
        JSON.stringify(userDetails)
      );

      if (status !== 200) throw new Error(data.info);

      //update state
      this.user = data.user;

      //save data to local storage
      const auth = {
        isAuthenticated: this.isAuthenticated,
        user: this.user,
        token: this.token,
      };

      localStorage.setItem("authDetails", JSON.stringify(auth));
      //return success true
      return true;
    },

    async editProfileImage(image) {
      const formData = new FormData();

      formData.append("images", image);

      //send request to backend
      const { data, status } = await apiClient(
        "/profile/image",
        "POST",
        formData
      );

      if (status !== 200) throw new Error(data.info);

      //update state
      this.user = data.user;

      //save data to local storage
      const auth = {
        isAuthenticated: this.isAuthenticated,
        user: this.user,
        token: this.token,
      };

      localStorage.setItem("authDetails", JSON.stringify(auth));
    },

    async login(loginDetails) {
      //send request to backend
      const { data, status } = await apiClient(
        "/login",
        "post",
        JSON.stringify(loginDetails)
      );

      if (status !== 200) throw new Error(data.info);

      //update state
      this.isAuthenticated = true;
      this.token = data.token;
      this.user = {
        _id: data._id,
        accountType: data.accountType,
        phone: data.phone || "",
        address: data.address || null,
        shippingDetails: data.shippingDetails || null,
        profileImage: data.profileImage || "",
        username: data.username,
        email: data.email,
        validEmail: data.validEmail,
      };
      //save data to local storage
      const auth = {
        isAuthenticated: this.isAuthenticated,
        user: this.user,
        token: this.token,
      };

      localStorage.setItem("authDetails", JSON.stringify(auth));
      //return success true
      return true;
    },
    logout() {
      //reset state
      this.isAuthenticated = false;
      this.user = null;
      this.token = "";

      //remove item form local storage
      localStorage.removeItem("authDetails");
    },
    async register(registerDetails) {
      //send request to backend
      const { data, status } = await apiClient(
        "/register",
        "post",
        JSON.stringify(registerDetails)
      );

      if (status !== 200) throw new Error(data.info);

      //update state
      this.isAuthenticated = true;
      this.token = data.token;
      this.user = {
        _id: data._id,
        accountType: data.accountType,
        username: data.username,
        email: data.email,
        validEmail: data.validEmail,
      };
      //save data to local storage
      const auth = {
        isAuthenticated: this.isAuthenticated,
        user: this.user,
        token: this.token,
      };

      localStorage.setItem("authDetails", JSON.stringify(auth));
      //return success true
      return true;
    },
    async resetPassword(password) {
      //send request to backend
      const { data, status } = await apiClient(
        `/password/reset/${this.resetPasswordToken}`,
        "PATCH",
        JSON.stringify({ password })
      );

      if (status !== 200) throw new Error(data.info);

      //reset password token
      this.resetPasswordToken = "";

      return true;
    },
  },
});
