<template>
  <form @submit.prevent="loginUser" class="p-md-5 p-3" @focusout="v$.$reset()">
    <div v-if="loginError !== ''" class="mb-3 text-danger">
      <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
      {{ loginError }}
    </div>
    <div class="mb-3">
      <label :class="[v$.email.$error && 'text-danger', 'form-label fs-6']"
        >EMAIL OR PHONE</label
      >
      <input
        v-model="data.email"
        type="text"
        :class="[v$.email.$error && 'border-danger', 'form-control no-outline']"
      />
      <span class="mt-3 text-danger fs-6" v-if="v$.email.$error">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
        {{ v$.email.$errors[0].$message }}
      </span>
    </div>

    <div class="mb-3">
      <label
        for="passwordInput"
        :class="[v$.password.$error && 'text-danger', 'form-label fs-6']"
      >
        PASSWORD
      </label>
      <div
        class="input-group mx-auto form-control d-flex justify-content-between"
      >
        <input
          v-model="data.password"
          :type="showPassword ? 'text' : 'password'"
          class="input border-0 no-outline"
        />
        <div class="input-group-append">
          <font-awesome-icon
            v-if="!showPassword"
            @click="showPassword = true"
            id="passwordVisibilityButton"
            icon="fa-regular fa-eye-slash"
          />
          <font-awesome-icon
            v-else
            @click="showPassword = false"
            id="passwordVisibilityButton"
            icon="fa-regular fa-eye"
          />
        </div>
      </div>
      <span class="mt-3 text-danger fs-6" v-if="v$.password.$error">
        <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
        {{ v$.password.$errors[0].$message }}
      </span>
    </div>
    <a
      @click="navigateToForgotPassword"
      class="text-decoration-none text-dark d-block mb-4"
      >Forgot password?
    </a>
    <button
      :disabled="loading"
      type="submit"
      class="zoom btn bg-success text-white w-100 mb-4"
    >
      <div v-if="loading" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span v-else>LOGIN</span>
    </button>

    <a
      @click="navigateToRegister"
      class="text-decoration-none d-block text-center"
      ><span class="text-dark">Don't have an account? </span>Create your account
      here</a
    >

    <LoginWithFacebook class="mt-4" />
    <LoginWithGoogle class="mt-4" />
  </form>
</template>

<script setup>
//imports
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import isEmail from "@/helpers/isEmail";
import LoginWithFacebook from "@/components/client/auth/LoginWithFacebook.vue";
import LoginWithGoogle from "@/components/client/auth/LoginWithGoogle.vue"
//components

//stores
import useNotificationStore from "@/stores/notificationStore";
import useAuthStore from "@/stores/authStore";
import useNavigationStore from "@/stores/navigationStore";

const { authFormState, nextRoute } = storeToRefs(useNavigationStore());
const { showNotification } = useNotificationStore();
const { login } = useAuthStore();

//router
const router = useRouter();

const data = ref({
  email: "",
  password: "",
});
const loginError = ref("");
const loading = ref(false);
const showPassword = ref(false);

//validation rules
const rules = {
  email: {
    required: helpers.withMessage("This field is required", required),
    // email: helpers.withMessage("Please, enter a valid Email", email),
  },
  password: { required: helpers.withMessage("Password is required", required) },
};

const v$ = useVuelidate(rules, data);

//methods
const navigateToForgotPassword = () => {
  authFormState.value = "forgotPassword";
};
const navigateToRegister = () => {
  authFormState.value = "register";
};

const loginUser = async () => {
  //validate form
  //if form is valid
  //call the auth store login method
  let loginDetails;
  const emailInputed = isEmail(data.value.email);

  if (emailInputed) {
    loginDetails = {
      email: data.value.email,
      password: data.value.password,
    };
  } else {
    loginDetails = {
      phone: data.value.email,
      password: data.value.password,
    };
  }

  try {
    //validate form using vuelidate
    v$.value.$validate();

    //if no error then send register request
    if (!v$.value.$error) {
      loading.value = true;
      const loginSuccess = await login(loginDetails);
      const notificationObject = {
        isSuccess: loginSuccess,
        header: "Success",
        text: "Signed in successfully",
      };
      showNotification(notificationObject);
      loading.value = false;

      //close model
      const modal = document.getElementById("formModal");
      const formModal = bootstrap.Modal.getInstance(modal);
      formModal.hide();
      if (nextRoute === "") return;

      //navigate to the next route
      router.push(nextRoute.value);

      //reset the value of next route after navigation
      nextRoute.value = "";
    }
  } catch (error) {
    error.name = "";

    loginError.value = error.toString();
    setTimeout(() => {
      loginError.value = "";
    }, 5000);
    loading.value = false;
  }
};
</script>

<style scoped>
.btn,
.form-control {
  border-radius: 50px !important;
}

#passwordVisibilityButton:hover {
  cursor: pointer;
}
</style>
