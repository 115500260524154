<template>
  <section>
    <div class="d-flex my-2 justify-content-between">
      <div class="d-flex">
        <img
          :src="image.sm"
          width="80"
          height="80"
          class="object-contain"
          alt=""
        />
        <div class="ms-4">
          <div>{{ name }}</div>
          <div>SKU: 2345</div>
          <div class="d-flex">
            <label class="me-2">Quantity:</label>
            <div class="d-flex align-items-center">
              <button
                @click="decrementQuantity(_id)"
                style="max-height: 20px"
                class="btn btn-sm bg-success text-white d-flex align-items-center"
              >
                -
              </button>
              <div id="quantity" class="px-2 fw-bold">{{ quantity }}</div>
              <button
                @click="incrementQuantity(_id)"
                style="max-height: 20px"
                class="btn btn-sm bg-success text-white d-flex align-items-center"
              >
                +
              </button>
            </div>
          </div>
          <div class="fw-bold text-success">{{ formattedPrice }}</div>
        </div>
      </div>

      <button
        @click="removeFromCart(_id, price)"
        style="height: 4px; width: 2px"
        type="button"
        class="btn-close text-reset bg-white"
      ></button>
    </div>
    <div class="line mt-2"></div>
  </section>
</template>

<script setup>
import { storeToRefs } from "pinia/dist/pinia";
import moneyFormatter from "@/helpers/moneyFormatter";
import { computed } from "vue";


import useCartStore from "@/stores/cartStore";
import useCurrencyStore from "@/stores/currencyStore";

const { removeFromCart, incrementQuantity, decrementQuantity } = useCartStore();
const {currencyData} = storeToRefs(useCurrencyStore());


const props = defineProps({
  _id: {
    type: String,
    required: true,
  },
  name: String,
  quantity: Number,
  price: Number,
  image: Object,
});

const formattedPrice = computed(() => {
  return moneyFormatter(props.price, currencyData.value);
});
</script>

<style scoped>
.line {
  height: 1px;
  width: 100%;
  background-color: gray;
  opacity: 0.4;
}
</style>
